.upload-card-container {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray_05);
  gap: 8px;
  padding-bottom: 11px;
  position: relative;

  .upload-card-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    background-color: var(--blue_strong);
    border-radius: 50%;
  }

  .small-loading-icon {
    animation: smallLoading 2s linear infinite;
  }

  @keyframes smallLoading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .upload-progress-load {
    background-color: var(--blue_strong);
    height: 2px;
    position: absolute;
    bottom: 0;
    animation-name: progressLoad;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  @keyframes progressLoad {
    from {
      right: 100%;
      width: 0;
    }
    to {
      right: 0;
      width: 300px;
    }
  }
}
