.btnWrapper {
  padding: 0 12px;
  box-sizing: border-box;
  height: 36px;
  border-radius: 6px;
  cursor: var(--pointer);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btnLoadingSvg {
  fill: var(--white);
}
