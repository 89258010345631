.float-menu-tag-container {
  width: 220px;

  background: var(--white);
  box-shadow:
    0 2px 7px rgba(0, 0, 0, 0.15),
    0 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .pro-option-card-tag {
    position: absolute;
    bottom: 266px;
    left: 218px;
  }

  .pro-option-card-tag-vertical {
    position: absolute;
    bottom: 269px;
    right: 219px;
  }

  .header-wrapper {
    width: 100%;
    padding: 7px 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .right-side {
      opacity: 0.3;
      cursor: pointer;

      &:hover {
        filter: brightness(1.3);
      }
    }
  }

  .icon-pencil {
    stroke: var(--gray_01);
    width: 15px;
    height: 15px;
  }

  .content-wrapper {
    width: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    border-top: 1px solid var(--gray_06);

    .edit-item {
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 25px;
      height: 25px;
    }

    .tag-item {
      width: 100%;
      padding: 2px 7px;

      border-radius: 4px;

      display: flex;
      justify-content: start;
      align-items: center;

      & > span {
        opacity: 0.5;
      }

      &:hover > span {
        opacity: 1;
      }

      &:hover .edit-item {
        visibility: visible;
      }

      .text-area {
        position: relative;

        display: flex;
        flex-direction: row;
        white-space: nowrap;

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          min-width: 187px;
          min-height: 28.3px;

          .clickable-item {
            width: 100%;
            text-align: left;
          }

          & > input {
            margin: 0;
            width: 100%;
            background: transparent;
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg {
              position: absolute;
            }
          }
        }

        .clear-item {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 25px;
          height: 25px;
        }

        .apply-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
