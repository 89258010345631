.emojiPicker-container {
  display: flex;
  flex-direction: column;
  width: 390px;
  background-color: var(--light_white);
  border-radius: 12px;
  height: 320px;
  overflow: hidden;

  .emojiPicker-extra-buttons-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;
  }

  .emojiPicker-extra-buttons {
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    border-radius: 5px;
  }

  .emojiPicker-change-skin-item {
    display: flex;
    width: 180px;
    background-color: var(--light_white);
    gap: 5px;
    z-index: 100000;
    flex-flow: row nowrap;
    position: absolute;
    top: -15px;
    right: 26px;
    border-radius: 5px;
    border: 1px solid var(--gray_04);
    padding: 5px;
  }

  .emojiPicker-custom-items {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .emojiPicker-custom-search {
    width: 266px !important;
    border-radius: 4px;
    height: 36px;
    border: 1px solid var(--gray_04);
    background-color: var(--light_white);
    color: var(--black);
    padding: 5px 5px 5px 33px;
    width: 230px;
  }

  .emojiPicker-custom-search:focus {
    border: 3px solid var(--blue_strong);
  }

  .emojiPicker-search-result {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: var(--light_white);
    padding: 15px;
    gap: 10px;
  }

  .emojiPicker-search-result-item {
    font-size: 20px;
    padding: 2px;
  }

  .emojiPicker-search-result-item:hover {
    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: var(--gray_04);
  }

  .emojiPicker-change-tab {
    padding-bottom: 7px;
    margin: 10px;
    width: 35%;
    color: var(--gray_04);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .emojiPicker-tabs-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray_04) !important;
  }

  .emojiPicker-delete {
    display: flex;
    align-items: center;
    color: var(--gray_01);
    margin: 10px;
    padding: 5px;
  }

  .emojiPicker-change-tab-item {
    display: flex;
    flex-flow: row nowrap;
  }

  .emojiPicker-custum-search-icon {
    position: absolute;
    left: 19px;
    top: 69px;
  }

  .emojiPicker-selected {
    border-bottom: 1px solid var(--black);
    color: var(--black) !important;
  }

  .loading-search {
    fill: var(--blue_strong);
  }

  .emojiPicker-search-result-item {
    padding: 2px;
    border-radius: 50%;
  }

  .emojiPicker-change-skin {
    left: 26px !important;
  }

  .emojiPicker-change-shuffle {
    left: 15px !important;
  }
}
