.contextMenuContainer {
  position: absolute;
  align-self: center;
  left: 0;
  width: 176px;
  right: 120px;
  margin-top: 410px;
  background-color: var(--white);
  padding: 2px;
  display: flex;
  border-radius: 4px;
  z-index: 200000;
  padding: 2px;
}

.contextMenuWrapper {
  width: 176px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.popupMenuProject {
  margin-top: 200px;
}
.popupMenuItem {
  /* width: 34px; */
  height: 34px;
  display: flex;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  align-items: center;
  justify-content: center;
}

.contextMenuItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: 36px;
  cursor: var(--pointer);
}

.contextMenuShortcutText {
  color: var(--gray_01);
  margin-right: 10px;
}

.contextMenuSubMenu {
  position: absolute;
  left: 183px;
  top: 120px;
}

.disableItem {
  color: var(--gray_03) !important;
}
