.template-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  min-width: 910px;

  .template-card-wrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: var(--white);
    gap: 20px;
    transition: all 0.4s;
  }

  .template-more-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .template-more {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue_light-2);
    width: 104px;
    height: 36px;
    border-radius: 6px;
    color: var(--blue_strong);
    text-align: center;
  }

  .template-more:hover {
    border: 1px solid var(--blue_strong);
  }

  .template-card-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--gray_04);
    height: 205px;
    width: 273px;
    background-color: var(--white);

    .template-card-img {
      display: flex;
      min-height: 140px;
    }

    .template-card-title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 16px 16px 16px;
      gap: 8px;
      height: 50%;
    }

    .template-card-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 3px;
      max-height: 60px;
    }

    .template-card-text {
      color: var(--gray_01) !important;
    }

    .template-card-feature {
      display: flex;
      flex-flow: row nowrap;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      color: var(--gray_01) !important;
    }

    .template-card-feature-wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 2px;
    }

    .template-card-img {
      // background-image: url('../assets/png/template_card.png');
      width: 100%;
      height: 148px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    &:hover .template-card-name {
      color: var(--blue_strong) !important;
    }

    .template-card-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: clip;
    }

    .template-card-text {
      text-overflow: ellipsis;
      overflow: clip;
      //max-height: 30%;
    }
  }

  .template-no-template-wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .no-template-title {
      font-size: 32px;
      font-weight: bolder;
    }
  }
}
