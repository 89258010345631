.board-container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  align-items: flex-end;
  right: 0;
  left: 0;
  background-color: var(--dark-50p);
  z-index: 4000;
}

.wrapper-text-editor {
  display: flex;
  flex-direction: column;
  /* padding: 11px 56px; */
  box-sizing: border-box;
  height: 100vh;
  width: 65%;
  padding: 4px 10px;
  background-color: var(--white);
  animation-duration: 200ms;
  animation-name: slideIn;
}

.main-menu-title-separator-nav {
  display: inline-flex;
  /* align-items: center;
  gap: 9px; */
}

.fullScr {
  width: 100%;
}

.navigationButtons {
  cursor: var(--pointer);
  margin-right: 5px;
}
.boardWrapper {
  overflow-y: scroll;
  position: relative;
  box-sizing: border-box;
  /* padding: 40px 0; */
  /* padding-right: 56px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.boardWrapper::-webkit-scrollbar {
  display: none;
}

.fullScrBoardWrapper {
  padding: 0 180px;
}
/* .popupMenuContainer {
  position: absolute;
  top: 60px;
  background-color: white;
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: ;
  border-radius: 4px;
  z-index: 1000;
  padding: 2px;
}

.popupMenuContainerProject {
  top: 130px;
}
.popupMenu {
  height: 36px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15), 0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}
*/
.board-menu {
  display: flex;
  flex-direction: row;
}

.board-upper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.menu-item {
  width: 36px;
  height: 36px;
  display: flex;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  align-items: center;
  justify-content: center;
}

.plus-svg {
  stroke: var(--gray_01) !important;
}
.wl {
  width: 50px;
}
.bullet {
  background-color: var(--black) !important;
  width: 9px !important;
  height: 7px !important;
  border-radius: 3.5px;
}
.bullet-container {
  width: 20px;
  margin-right: 10px;
}
.popupMenuColumns {
  position: absolute;
  background-color: var(--white);
  width: 153px;
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 8px;
  z-index: 1000;
}

.sendOptionsWrapper {
  background-color: var(--white);
  width: 171px;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 0;
  z-index: 1000;
}
.popupMenuColorPanel {
  position: absolute;
  background-color: var(--white);
  height: 32px;
  width: 362px;
  display: flex;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}
.popupMenuColorPanelToolbar {
  position: absolute;
  background-color: var(--white);
  left: 140px;
  display: flex;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.statusItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}

.attach-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 6px;
  background-color: var(--gray_06);
}

.attach-item {
  display: inline-flex;
}

.modalTitleItems {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  min-height: 36px;
  padding: 10px;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}
.modalTitleItems:hover {
  background: var(--gray_06);
}

.emojiDeleteIcon {
  position: absolute;
  right: -5px;
  bottom: 0px;
  width: 15px;
  height: 12px;
}
.modalStyleNote {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 100;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.modalStyleNoteItems {
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  margin: 1px;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}
.modalStyleNoteItemsSelected {
  background-color: var(--gray_06);
}

.modalStyleNoteItems:hover {
  background: var(--gray_06);
}

.modalStyleNoteItemFill {
  height: 14px;
  width: 14px;
}
.modalStyleNoteItemStroke {
  height: 14px;
  width: 14px;
}
.modalDocument {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 1px;
  padding: 8px 0px;
  width: 150px;
  height: 124px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.modalDocumentItems {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: 36px;
  padding: 20.5px 10px;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}
.modalDocumentItems:hover {
  background: var(--gray_06);
}

input {
  border: 0;
  outline: var(--white);
}

textarea {
  padding: 0 56px;
  width: calc(100% - 52px);

  border: 0;
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
textarea::-webkit-scrollbar {
  display: none;
}

/* .notranslate {
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 3px 2px;
} */
[contentEditable] {
  /* margin: 0 0 0 52px; */
  outline: none;
}

.editable {
  display: flex;
}
.editable:before {
  content: attr(placeholder);
  color: lightgray;
  display: block;
  position: absolute;
  font-family: 'Campton', sans-serif;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: var(--gray_07);
  font-style: italic;
}
@keyframes slideIn {
  from {
    margin-right: -55%;
  }

  to {
    margin-right: 0;
  }
}

.task-board-menu-container {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 256px;
  /* height: 353px; */
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 100;
}

.task-board-menu-item-container {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 12px;
}

.task-board-menu-child {
  background: var(--gray_05);
  width: 90%;
  height: 1px;
  align-self: center;
}

.task-board-menu-name {
  margin-left: 8px;
}

.float-menu-color-container {
  top: 27px;
  left: 0;
}

.float-menu-color-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.float-menu-color-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 5px;
}

.float-menu-link-container {
  top: 27px;
  height: 70px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  flex: 1;
  flex-direction: column;
}

.float-menu-link-text {
  text-align: start;
  width: 100%;
}

.float-menu-fill-link-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.float-menu-fix-url {
  width: 250px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-color: var(--gray_03);
  border-style: solid;
  border-radius: 6px;
  margin-right: 10px;
  padding: 5px;
  height: 30px;
}

.float-menu-send-link {
  width: 100%;
  margin: 0;
  background-color: var(--white);
}

.task-board-sub-menu-container {
  position: absolute;
  left: 265px;
  z-index: 100;
}

.task-board-sub-menu-title-container {
  display: flex;
  width: 100%;
  padding: 0 10px 4px 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
}
.task-board-sub-menu-title-container > div {
  color: var(--gray_03) !important;
}
.task-board-sub-menu-main-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.task-board-sub-menu-title-line {
  min-height: 2px;
  height: 2px;
  width: 100%;
  background-color: var(--gray_06);
}

.task-board-sub-menu-group-title {
  color: var(--gray_03) !important;
  margin-top: 5px;
  margin-left: 10px;
}

.title-dark-color {
  color: var(--black) !important;
}
.task-board-container {
  cursor: default;
}

.task-board-label {
  margin-left: 4px;
}

.task-board-load {
  opacity: 0.6;
}

.task-board-name {
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.6;
}

.task-board-check-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50px;
  height: 34px;
  border-radius: 3px;
  margin-right: 2px;
}

.task-board-check-date {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50px;
  height: 34px;
  border-radius: 3px;
  margin-right: 2px;
}

.task-board-check-color {
  width: 17px;
  height: 17px;
  border-radius: 10px;
}

.task-board-menu-separator {
  width: 1px;
  height: 20px;
  background: var(--gray_04);
  margin: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.task-board-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}

.task-board-date {
  background-color: var(--green_light);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}

.task-board-color {
  width: 17px;
  height: 17px;
  border-radius: 10px;
}

.task-board-menu-status {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-board-menu-status-checked {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4px;
}

.task-board-menu-date {
  position: absolute;
  z-index: 2;
}

.task-board-menu-color-container {
  right: 20px;
}

.task-board-menu-color-items {
  height: 100%;
  display: flex;
  align-items: center;
}

.task-board-menu-color-chosen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 5px;
}

.task-board-menu-color-checked {
  width: 12px;
  height: 12px;
  border-radius: 10px;
}

.task-board-menu-upload {
  position: absolute;
  top: -8px;
  left: 80px;
}

.task-board-menu-resize {
  display: flex;
  justify-content: flex-end;
  margin-right: 72px;
}

.task-board-resize-label {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-board-menu-resize-separator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4px;
}

.task-board-show-emoji {
  padding-left: 56px;
  width: 120px;
  margin: 0;
}

.task-board-timer-container {
  display: inline-flex;
  align-items: center;
  padding-left: 56px;
  margin-top: 16px;
}

.task-board-timer-text {
  color: var(--gray_01) !important;
  margin-left: 6px;
}

.task-board-title {
  width: 100%;
  height: 76px;
  margin-left: 50px;
  background-color: var(--white);
}

.task-board-emoji-container {
  position: absolute;
  top: 10px;
  left: 50px;
}

.task-board-loading {
  margin-left: 75px;
  margin-top: 20px;
}

.task-board-note-container {
  overflow-y: auto;
  height: 80vh;
  cursor: text;
}

.task-note-input-container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.task-note-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.task-note-add {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-note-oppen-menu {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-note-empty-access {
  width: 73px;
}

.task-note-length {
  width: 85%;
}

.task-note-sort {
  margin-right: 10px !important;
  width: 20px;
}

.taskBoardActionFloatMenu {
  position: absolute !important;
  top: 43px !important;
  left: 10px !important;
}

.task-note-edit {
  display: inline-flex;
  width: 95%;
  margin: 0;
  height: auto;
  resize: none;
  padding: 0;
  padding-top: 5px;
  margin-bottom: 5px;
}

.text-bold {
  font-weight: 600;
}
.text-italic {
  font-style: italic;
}
.text-underline {
  color: inherit;
  border-bottom: 0.05em solid;
  word-wrap: break-word;
}
.text-line-through {
  text-decoration: line-through;
}

/* .holder:before {
  content: attr(placeholder);
  color: lightgray;
  display: block;
  position: absolute;
  font-family: 'Campton', sans-serif;
} */

/* blacknote override */

.bn-block-group {
  margin-left: 0 !important;
}
.bn-block-group
  .bn-block-group
  .bn-block-outer:not([data-prev-depth-changed])::before {
  border-left: none !important;
}
