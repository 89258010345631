.modal-container {
  cursor: default;
  position: fixed;
  z-index: 5000;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-30p);
}

.modal-move-grid-container {
  padding: 0 30px;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 60px;
}

.modal-move-grid-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.modal-move-grid-container div {
  align-self: center;
}

.modal-move-folders-container {
  margin-top: 10px;
  padding: 15px;
  padding-top: 5px;
}

.modal-move-address-field {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray_01);
  font-size: 14px;
  border-radius: 5px;
  padding: 3px 7px;
  min-height: 32px;
}

.modal-wrapper {
  width: 500px;
  background: var(--white);
  border-radius: 6px;
  padding: 12px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-text {
  margin-top: 13px;
  color: var(--black);
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

/* Move-in Modal */

.modal-move-in-wrapper {
  width: 286px;
  height: 220px;
  background: var(--white);
  border-radius: 6px;
  padding: 12px 0;
}

.modal-move-in-search-wrapper {
  margin: 0 8px;
  padding: 8px;
  height: 32px;
  width: 268px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid var(--blue_strong);
  display: flex;
  align-items: center;
}

.modal-move-in-title {
  color: var(--gray_01);
  margin: 5px 0 5px 9px;
}

.modal-move-in-items-wrapper {
  overflow-y: auto;
  height: 200px;
  margin-top: 7px;
}

.modal-menu-member-more-items-wrapper {
  overflow-y: auto;
  max-height: 150px;
  margin-top: 7px;
  width: 100%;
}

.group-by-section {
  & label {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    cursor: var(--pointer);
  }

  & input[type='checkbox'] {
    width: 15px;
    height: 15px;
    margin: 0 7px 0 13px;
  }
}

.modal-internal-note-items-wrapper {
  overflow-y: auto;
  height: 150px;
  padding: 10px;
}

.menu-modal-members-wrapper {
  height: 150px;
  width: 209px;
}

.modal-move-in-items {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: var(--pointer);
}
.modal-member-in-items {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: var(--pointer);
}

.modal-move-in-items:hover {
  background-color: var(--gray_06);
}

.modal-move-in-folder-icon {
  width: 17.5px;
  height: 15px;
  margin-top: 3px;
  margin-left: 9.5px;
}

.modal-move-in-files-icon {
  width: 18px;
  height: 18px;
  margin-top: 3px;
  margin-left: 9.5px;
}

.modal-move-in-item-text {
  margin-left: 7px;
}

.member-card-menu {
  position: absolute;
  overflow: auto;
  top: 4px;
  left: -44px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 209px;
  height: 150px;
  padding: 12px 8px 6px 8px;
}

.member-menu-grid-container {
  position: absolute;
  top: 22px;
}

.modal-email-suggestion {
  background-color: var(--blue);
  width: 100%;
  margin-top: 20px;
  height: 55px;
  padding: 10px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  cursor: var(--pointer);
}
.modal-dropdown {
  width: 110px;
  position: absolute;
  left: -56px;
  background-color: var(--white);
  padding: 9px;
  border: 1px solid var(--gray_03);
  border-radius: 8px;
  z-index: 20;
}
.chevron-modal {
  stroke: var(--white) !important;
  width: 10px;
  height: 20px;
  margin-left: 7px;
  margin-top: 2px;
}
.svg-modal {
  fill: var(--black);
  width: 15px;
  height: 15px;
  margin-right: 7px;
  margin-top: 2px;
}

.member-card-name {
  margin-top: 10px;
}

.member-card-email {
  color: var(--gray_01);
}

.member-card-remove {
  margin-top: 14px;
}

.member-card-remove-text {
  margin-left: 8px;
  padding: 2px;
}

.modal-export-container {
  width: 400px;
}

.modal-export-download-container {
  margin-top: 20px;
}

.modal-export-download-check {
  margin-top: 12px;
}

.modal-export-download-text {
  margin-left: 10px;
}

.import-has-error {
  color: var(--red) !important;
}

.import-without-error {
  color: var(--gray_01);
}

.modal-export-download-name {
  color: var(--gray_01);
}

.modal-export-buttons {
  width: 50%;
  float: right;
}

.modal-invite-search {
  margin-top: 12px;
}

.modal-invite-member-name {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.modal-invite-email {
  color: var(--white);
  line-height: 1.4;
}

.modal-invite-username {
  color: var(--white);
  line-height: 1.4;
  text-align: start;
}

.modal-invite-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
}

.modal-invite-access-text {
  color: var(--white);
  line-height: 1.4;
}

.modal-invite-access-edit {
  margin-top: 10px;
}

.modal-invite-access-view {
  margin-bottom: 5px;
}

.modal-export-radio-icon {
  width: 20px;
  height: 20px;
}

.modal-export-icons {
  margin-left: 10px;
}

.modal-unlock-container {
  background-color: var(--white);
  width: 445px;
  height: 200px;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.popUp-container {
  position: fixed;
  right: 201px;
  bottom: 20px;
}

.modal-unlock-button {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}
