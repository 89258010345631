.modal-sharing-container {
  width: 435px;
  height: 446px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--white);
  padding: 6px 0 8px 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 10px;

  .modal-sharing-top-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .modal-sharing-header {
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray_04);
  }

  .modal-sharing-tabs {
    display: flex;
    flex-flow: row nowrap;
    gap: 11px;
  }

  .modal-sharing-tab-item {
    padding: 4px 4px 6px 4px;
    color: var(--gray_01) !important;
    transition: all 0.3s;
  }

  .modal-sharing-tab-active {
    border-bottom: 2px solid var(--blue_strong);
    color: var(--black) !important;
    transition: all 0.3;
  }

  .modal-sharing-close {
    padding: 2px;
  }

  .modal-sharing-share {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .modal-sharing-share-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .modal-sharing-share-link {
    width: 100%;
    margin-bottom: 12px;
  }

  .padding-md {
    padding: 0 12px;
  }

  .modal-sharing-share-fast-export {
    width: 100%;
    min-height: 206px;
  }

  .modal-sharing-buttons {
    border-top: 1px solid var(--gray_04);
    padding: 8px 12px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .pro-option-export {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .invite-container-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .copy-link-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  .copy-link-title {
    color: var(--gray_01) !important;
  }

  .copy-link-text-container {
    display: flex;
    border: 1px solid var(--gray_04);
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
    height: 36px;
    border-radius: 4px;
    padding: 6px 0 6px 8px;
  }

  .copy-link-text-access {
    min-width: 130px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .copy-link-access-text {
    margin-right: 7px;
  }

  .copy-link-icon {
    stroke: var(--white);
  }

  .copy-link-icon-link {
    display: flex;
    align-items: center;
  }

  .copy-link-input {
    width: 100px;
    color: var(--gray_01);
    background-color: var(--white);
    text-overflow: ellipsis;
  }

  .copy-link-change-access {
    display: flex;
    flex-direction: column;
    padding: 10px 8px;
    border: 1px solid var(--gray_04);
    border-radius: 10px;
    width: 173px;
    background-color: var(--white);
    position: absolute;
    right: -12px;
    top: 8px;
    gap: 3px;
  }

  .copy-link-change-access-invite {
    display: flex;
    flex-direction: column;
    padding: 10px 8px;
    border: 1px solid var(--gray_04);
    border-radius: 10px;
    width: 173px;
    background-color: var(--white);
    position: absolute;
    right: -8px;
    top: 20px;
    gap: 3px;
  }

  .copy-link-access-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 4px 6px;
    align-items: center;
    border-radius: 6px;
  }

  .copy-link-access-icon-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 7px;
    align-items: center;
  }

  .copy-link-access-item:hover {
    background-color: var(--gray_06);
  }

  .copy-link-access-icon {
    display: flex;
    align-items: center;
  }

  .modal-sharing-link-button {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    background-color: var(--white);
    height: 36px;
    padding: 10px 5px;
    width: 100px;
    color: var(--blue_strong);
  }

  .modal-sharing-icon {
    stroke: var(--blue_strong) !important;
    display: flex;
    align-items: center;
  }

  .modal-sharing-invite-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
  }

  .modal-sharing-invite-member {
    border: 1px solid var(--gray_04);
    padding: 2px;
    width: 333px;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .modal-sharing-invite-email {
    position: absolute;
    z-index: 1;
    left: 5px;
    width: 333px;
    padding: 5px;
    color: var(--gray_01);
    margin-left: 7px;
    border-radius: 0 0 4px 4px;
    background-color: var(--white);
    border: 1px solid var(--gray_04);
  }

  .invite-verify-email-container {
    display: flex;
    gap: 3px;
    flex-direction: column;
  }

  .modal-sharing-invite-verify-email {
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 3px;
    background-color: var(--blue_light-2);
    max-width: 287px;
    width: fit-content;
    border-radius: 19px;
    padding: 3px 7px;
    justify-content: space-between;
    align-items: center;
  }

  .access-select {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    cursor: pointer;
  }
  .invite-verify-email-icon {
    height: 10px;
    width: 10px;
  }

  .modal-sharing-invite-input {
    width: 98%;
    background-color: var(--white);
  }

  .modal-sharing-invite-input-active {
    border: solid 2px var(--blue_strong);
    transition: all 0.1s;
  }

  .modal-sharing-invite-list-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    min-height: 232px;
  }

  .modal-sharing-invite-list-item-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 3px;
  }

  .invite-list-item-name {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
  }

  .modal-sharing-export-container {
    background-color: var(--white);
    max-height: 328px;
    overflow: scroll;
    padding: 0 12px;
  }

  .invite-list-item-text {
    color: var(--connectors-levels);
  }
}
