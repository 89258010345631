.nodes-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  gap: 7px;
  margin-top: -2px;

  .nodes-list-card-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 2px;
  }

  .nodes-list-card-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 42px;
    border-radius: 8px;
    background-color: var(--gray_06);
  }

  .nodes-list-status-icon {
    width: 23px;
    height: 21px;
  }

  .nodes-list-card-feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--yellow_light);
    width: 97%;
    padding: 0 12px;
  }

  .nodes-list-task-feature {
    display: flex;
    flex-flow: row nowrap;
    gap: 37px;
    padding: 10px;
  }

  .nodes-list-date-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 3px;
    width: 20%;
  }

  .nodes-list-date-icon {
    stroke: var(--gray_01);
  }

  .nodes-list-date-container:hover .nodes-list-date-text {
    color: var(--black) !important;
  }

  .nodes-list-date-icon-alert {
    stroke: var(--red);
  }

  .nodes-list-date-container:hover .nodes-list-date-icon {
    stroke: var(--black);
  }

  .nodes-list-date-container:hover .nodes-list-date-icon-alert {
    stroke: var(--black);
  }

  .nodes-list-task-name-container {
    width: 40%;
    margin-right: 150px;
  }

  .nodes-list-task-name {
    background-color: var(--yellow_light);
  }

  .check-list-title-member-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    width: 20%;
  }

  .check-list-title-member {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    padding: 5px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  }

  .check-list-title-more {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    justify-content: center;
  }

  .check-list-title-member-more {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    padding: 6px 4px;
    border-radius: 6px;
    width: 35px;
    height: 24px;
  }

  .nodes-list-card-more-icon {
    visibility: hidden;
  }

  .nodes-list-card-container:hover .nodes-list-card-more-icon {
    visibility: visible;
  }

  .nodes-list-card-more {
    height: 20px;
    display: flex;
    align-items: center;
  }

  .nodes-list-more-menu {
    position: absolute;
    right: 0;
  }

  .nodes-list-status {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .nodes-list-status-position {
    position: absolute;
    top: -350px;
    left: -215px;
  }

  .nodes-list-card-member-menu {
    position: absolute;
    right: 370px;
  }

  .nodes-list-card-calendar {
    position: absolute;
    right: 0;
    top: 18px;
  }

  .nodes-list-feature-separator {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
  }
}

.nodes-more-menu-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  width: 256px;
  // height: 349px;

  .nodes-more-menu-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 7px 8px;
  }

  .nodes-more-menu-item:hover {
    background-color: var(--gray_06);
  }

  .nodes-more-menu-item-name {
    display: flex;
    flex-flow: row nowrap;
    gap: 3px;
    align-items: center;
  }

  .nodes-more-menu-item-key {
    color: var(--gray_02);
  }

  .nodes-more-menu-icon {
    width: 20px;
    height: 17px;
  }

  .nodes-more-menu-separator {
    width: 100%;
    height: 1px;
    background-color: var(--gray_05);
  }

  .nodes-menu-icon-chevron {
    fill: var(--gray_02) !important;
  }
}

.nodes-more-calendar--position {
  position: absolute;
  top: -350px;
  right: 255px;
}

.nodes-more-color-position {
  position: absolute;
  right: 218px;
  top: -57px;
}

.nodes-more-user-position {
  position: absolute;
  right: 254px;
  top: -229px;
}
