.switch-container {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch-item {
  display: none;
  -webkit-appearance: none;
}

.switch-container .switch-item {
  width: 0 !important;
  height: 0 !important;
}

.switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray_0);
  transition: 0.4s;
  border-radius: 34px;
}

.switch-slider::before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2.8px;
  background-color: var(--white);
  transition: 0.4s;
  border-radius: 50%;
}

.switch-item:checked + .switch-slider {
  background-color: var(--blue_strong);
}

.switch-item:checked + .switch-slider::before {
  transform: translateX(12px) !important;
}
