.loading-container {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  background-color: var(--gray_02);
}
.loading-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 300px; */
  text-align: center;
}
.loading-gif {
  width: 75px;
  height: 75px;
}
.loading-page-spinner {
  fill: var(--gray_01);
  width: 24px;
  height: 24px;
}

.loading-wrapper {
  width: 24px;
  height: 24px;
}

.loading-text {
  color: var(--gray_01);
}

.loading-page-spinner-account {
  fill: var(--white);
  width: 20px;
  height: 20px;
}
.loading-wrapper-account {
  width: 20px;
  height: 20px;
}

.loading-spinner-account {
  display: grid;
  width: 20px;
  margin-left: 4.5px;
  animation: spinner 1.5s linear infinite;
}
