.connectors {
  stroke: var(--connectors);
  animation-name: fade;
  animation-duration: 0.8s;
}
@keyframes fade {
  from {
    stroke: transparent;
  }
  to {
    stroke: var(--connectors);
  }
}
.connectors-levels {
  stroke: var(--connectors-levels);
  animation-name: fadeLevels;
  animation-duration: 0.8s;
}
@keyframes fadeLevels {
  from {
    stroke: transparent;
  }
  to {
    stroke: var(--connectors-levels);
  }
}
