.container {
  /* position: fixed;
  height: 50px;
  left: 0px;
  right: 0;
  top: 0px;

  z-index: 2000; */
}

.mainMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 12px;
  box-sizing: border-box;
  /* width: "100%"; */
  height: 44px;

  background: var(--white);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.main-menu-features {
  display: flex;
  flex: 1.2;
  flex-direction: row;
  align-items: center;
}

.board-main-menu-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--blue_strong);
  margin: 0 6px;
}

.main-menu-add-tooltip {
  position: absolute;
  top: 163% !important;
  left: -7% !important;
  margin-left: 0 !important;
}

.main-menu-features-expend {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5;
  padding: 7;
}

.main-menu-features-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-menu-features-filter-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -3.5px;
  right: -2.5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: var(--lavender);
}

.editor-main-menu-add-to-dashboard-button {
  margin-left: 7px;
}

.main-menu-features-select {
  width: 35px;
  border-radius: 5px;
}

.main-menu-features-todo {
  border-radius: 5px;
  height: 34px;
  padding: 5px;
}

.main-menu-nav {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
}

.main-menu-nav-title-input {
  width: 10%;
  background-color: var(--white);
}
.main-menu-title-icon {
  margin-left: 4px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 180px;
}
.main-menu-navigator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
}
.main-menu-title {
  display: flex;
  flex-flow: row nowrap;
  gap: 2px;
  align-items: center;
}

.main-menu-last-title {
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
}
.main-menu-title-separator {
  margin-left: 5px;
  margin-right: 5px;
}

.main-menu-title-separator-style {
  display: inline-flex;
  align-items: center;
  gap: 9px;
}

.main-menu-right-side {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

.main-menu-accounts {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
}

.main-menu-account-email {
  color: var(--gray_01);
}

.main-menu-account-data {
  margin-top: 5px;
}

.main-menu-account-profile {
  display: inline-flex;
  align-items: center;
}

.main-menu-account-tooltip {
  left: 6px !important;
  top: 38px !important;
}

.main-menu-export {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  width: 35px;
  height: 35px;
  margin-right: 7px;
}

.filter-menu-status-title {
  text-align: left;
  color: var(--gray_01);
}

.filter-menu-status-item {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
}

.filter-menu-status-name {
  color: var(--black);
}

.filter-menu-status-check-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4;
}

.filter-color-title {
  text-align: left;
  color: var(--gray_01);
  margin-top: 12;
}

.filter-color-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter-color-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-color-check-item {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.menu {
  background: var(--white);
  position: absolute;
  top: -8px;
  left: 123px;
  width: 186px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 6px;
}

.statusItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: url('../assets/svg//cursor-pointer.svg'), auto;
}

.menu-buttons {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.board-main-menu-buttons {
  width: 35px;
  height: 35px;
  margin: 0 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chevron-main-menu {
  width: 10px;
  height: 5px;
  margin-left: 3px;
}
.chevron-main-menu-white {
  stroke: var(--white);
  width: 10px;
  height: 5px;
  margin-left: 3px;
}

.plus-main-menu {
  stroke: var(--white) !important;
}

.sharing-card-menu {
  position: absolute;
  min-height: 90px;
  left: 90px;
  top: -4px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 280px;
  padding: 5px 0;
}

.sharing-card-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 10px 10px;
  width: 100%;
}
.sharing-card-view-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: var(--pointer);
}
.sharing-card-view-wrapper:hover .gray-1-icon {
  fill: var(--black);
}
.sharing-card-view-wrapper:hover .sharing-card-view-text {
  color: var(--black);
}
.sharing-card-view-text {
  color: var(--gray_01);
}

.levels-card-menu {
  position: absolute;
  left: 219px;
  width: 171px;
  top: -4px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0;
}
.levels-card-menu-items {
  display: flex;
  width: 100%;
  padding: 6px 0 6px 8px;
  align-items: center;
  justify-content: space-between;
  cursor: var(--pointer);
}
.levels-card-menu-items-text {
  margin-left: 6px;
}
.levels-card-checkMark {
  margin: 0 7px 0 13px;
}
.blue-icon {
  width: 14px;
  height: 14px;
  stroke: var(--blue_strong) !important;
}
.gray-1-icon {
  fill: var(--gray_01);
}

.sharing-member-delete-icon {
  stroke: var(--gray_01);
}
.sharing-member-delete-icon:hover {
  stroke: var(--black);
}
.board-main-menu-member {
  width: 35px;
}
.board-main-menu-filter {
  width: 40px;
}
.main-menu-todo-btn {
  position: absolute;
}

.arrowRotate-icon {
  width: 18px !important;
  height: 18px !important;
}

.arrowRotateLeft-icon {
  transform: scaleX(-1);
}

.main-menu-back-tooltip {
  margin-left: -18px !important;
  margin-top: -3px;
}

.main-menu-todo-tooltip {
  margin-left: -22px !important;
  margin-top: -3px;
}

.main-menu-filter-tooltip {
  margin-left: -16px !important;
  top: 233% !important;
}

.main-menu-multiSelect-tooltip {
  min-width: 90px !important;
  margin-left: -44px !important;
  margin-top: -3px;
}

.main-menu-layers-tooltip {
  margin-left: -22px !important;
  margin-top: -3px;
}

.main-menu-changeNodes-tooltip {
  min-width: 100px !important;
  margin-left: -50px !important;
  margin-top: -3px;
}
