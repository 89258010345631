.team-container {
  flex: 1;
  padding: 24px;
}

.team-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.team-items {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid var(--gray_05);
}
.team-item-name {
  width: 40%;
  display: flex;
  align-items: center;
}
.team-item-name-text {
  margin-left: 10px;
}
.team-item-time {
  color: var(--gray_01);
  width: 20%;
}
.team-item-email {
  width: 30%;
}
.team-item-more {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

/* 

.team-item-add-member {
  width: 20.8%;
  display: flex;
  align-items: center;
  line-height: 24px;
  color: var(--gray_01);
}
.team-item-plus {
  stroke: black;
  margin-left: 6.5px;
}
.team-item-members {
  width: 18%;
} */
