.bn-default-styles p {
  font-weight: 400;
  font-size: 16px;
  /* line-height: 30px; */
  letter-spacing: -0.014em;
  color: var(--black);
}
/* .bn-block-outer {
  margin-bottom: 10px;
  margin-left: 8px;
  cursor: text;
} */

/* .bn-side-menu {
  margin-top: 7%;
  display: flex;
  gap: 5px;
} */
.bn-inline-content {
  background-color: var(--white) !important;
}
.bn-editor {
  background-color: var(--white) !important;
}
.bn-inline-content {
  color: var(--black);
}
.bn-block-outer {
  margin-bottom: 12px;
}
.bn-side-menu {
  margin-top: 0;
}

.side-menu-item {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: var(--pointer);
}

.side-menu-icon {
  margin-right: 10px;
}
