.comments-container {
  position: fixed;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px 10px 20px;
  top: 60px;
  bottom: 20px;
  right: 10px;
  width: 350px;
  border-radius: 10px;
  background-color: var(--white);
}
.comment-reply {
  margin-left: 25px;
}
.comments-title-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.comments-title {
  margin-bottom: 10px;
}
.comments-close {
  cursor: var(--pointer);
}
.comments-main-topic-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--gray_04);
  border-radius: 6px;
  padding: 10px;
  margin: 20px 0 10px;
}
.comments-main-topic-textarea {
  resize: vertical;
  vertical-align: center;
  padding: 3px 7px;
  min-height: 33px;
  height: 20px;
  margin-top: 5px;
  width: 100%;
  border: 1px solid var(--gray_05);
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);
}
.comments-main-topic-textarea::placeholder {
  color: var(--gray_04);
}
.comments-main-topic-buttons-wrapper {
  display: flex;
  width: 50%;
  align-self: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}
.comments-comment-container {
  width: 100%;
  border: 1px solid var(--gray_04);
  border-top: 8px solid var(--lavender);
  border-radius: 6px;
  padding: 10px 10px 0;
  margin: 10px 0 10px;
}
.comments-comment-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.comments-comment-section:hover .comments-comment-more {
  display: flex;
}
.comments-comment-section:hover .comments-comment-time {
  display: none;
}
.comments-user-wrapper {
  display: flex;
  flex-direction: row;
}
.comments-comment-data {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.comments-avatar-container {
  margin-right: 5px;
}
.comments-like-readonly,
.comments-like-container {
  width: 26px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.comments-like-container:hover .comments-like {
  display: none;
}
.comments-like-container:hover .comments-liked {
  display: block;
}
.comments-like {
  display: block;
  cursor: var(--pointer);
}
.comments-liked {
  display: none;
  cursor: var(--pointer);
}

.comments-comment-more {
  display: none;
  position: relative;
  height: 30px;
  width: 50px;
  align-items: center;
  cursor: var(--pointer);
  justify-content: space-between;
}
.comments-comment-submenu {
  position: absolute;
  top: 28px;
  right: 0;
  border-radius: 5px;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
}
.comments-comment-submenu-item {
  padding: 5px 13px;
}
.more-icon {
  margin-bottom: 4px;
}
.back-icon {
  color: black;
}
.comments-comment-details {
  word-break: break-all;
  margin-left: 30px;
}
.comments-comment-details-reply {
  word-break: break-all;
  margin-left: 20px;
}
.comments-button-show-all {
  margin-top: 10px;
}
.comments-comment-time {
  display: block;
  height: 30px;
  line-height: 22px;
}
.line-h-16 {
  line-height: 16px;
}
.mv-5 {
  width: unset;
  margin: 15px 0px;
}
.comment-text-deleted {
  color: var(--gray_01);
  font-style: italic;
}
