.toastContainer {
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.toastWrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  /* left: calc(50% - 97px); */
  left: 50%;
  transform: translate(-50%, 0);
  height: 36px;
  padding-left: 10px;
  margin-bottom: 18px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 4500;
}

.toastText {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  margin-right: 10px;
}

.cancelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue_strong);
  width: 77px;
  margin-left: 10px;
  height: 100%;
  color: var(--white);
  font-size: 14px;
  border-radius: 0 5px 5px 0;
}

.footerContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  bottom: 10px;
  right: 0;
  margin: 15px;
}
.scaleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 46px;
}

.scaleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98px;
  height: 32px;
  padding: 2px 10px;
  background: var(--white);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  border-radius: 4px;
  float: right;
}

.scaleSelector {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 36px;
  min-height: 140px;
  padding: 3px 0;
  background: var(--white);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.scaleSelectorItemWrapper {
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 36px;
}

.scaleSelectorText {
  margin-left: 7px;
}

.viewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 180px;
}

.viewWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 32px;
  padding: 2px 10px;
  background: var(--white);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  border-radius: 4px;
}

.viewSelectorItemWrapper {
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}

.viewSelector {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 130px;
  padding: 8px;
  background: var(--white);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.footer-check-mark {
  margin-left: 8px;
  margin-right: 5px;
}

.footer-selector-checkMark {
  margin-left: 8px;
}

.slider {
  -webkit-appearance: none;
  width: 68px;
  height: 4px;
  border-radius: 5px;
  background: var(--gray_05);
  outline: none;
  opacity: 0.7;
  transform: rotate(-90deg);
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  background: url('../assets/png/range.png');
  cursor: var(--pointer);
}

.slider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  background: url('../assets/png/range.png');
  cursor: var(--pointer);
}

.scaleSelector-scroll-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.scale-selector-icon {
  width: 13px;
  height: 13px;
}

.scale-selector-plus {
  padding: 3px 6px;
  border-radius: 6px !important;
}

.slider-selected {
  background: var(--blue);
  opacity: 0.5 !important;
  height: 4px;
}

.slider-selected::-webkit-slider-thumb {
  background: url('../assets/png/selected-range.png');
}

.slider-selected::-moz-range-thumb {
  background: url('../assets/png/selected-range.png');
}

.scale-name-tooltip {
  width: 50px !important;
  right: 84% !important;
}
