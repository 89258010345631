.affiliate-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px 30px;
  margin-bottom: 150px;

  .affiliate-statics {
    padding-inline-start: 25px;
    margin-block-start: 10px;
    margin-block-end: 0;
  }

  .affiliate-link {
    color: var(--red);
  }

  .affiliate-link-container {
    display: flex;
    flex-direction: column;
  }

  .affiliate-started {
    padding-inline-start: 25px;
    margin-block-start: 5px;
    margin-block-end: 0;
  }

  .affiliate-link-support {
    color: var(--blue);
  }

  .affiliate-text-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
  }
}
