.dashboard {
  display: flex;
  height: fit-content;
  min-height: 100vh;
}
.dashboard-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--white);
}

.dashboard-breadcrumb {
  margin: 20px 0 20px 30px;
}

.dashboard-breadcrumb-item {
  margin-left: 20px;
}

.dashboard-breadcrumb-back {
  background-color: var(--gray_05);
  padding: 2px 5px;
  border-radius: 5px;
}

.dashboard-breadcrumb-back div {
  margin-left: 5px;
}

.empty-project {
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-project-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.project-container {
  padding: 24px;
  background-color: var(--white);
}

.project-container-ai {
  padding: 0 24px 0 24px;
  background-color: var(--white);
}

.project-table {
  margin-top: 10px;
  width: 100%;
}

.multi-selection-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
  width: 175px;
  background-color: var(--white);
}

.empty-new-project-menu {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 130px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.card-body-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.no-more-data-list {
  text-align: center;
  color: var(--gray_01) !important;
  padding-top: 20px;
}

.project-items {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid var(--gray_05);
  cursor: grab;
  padding: 5px;
}
.project-items-checkbox {
  margin-right: 20px;
  width: 15px;
  height: 15px;
}

.drag-bottom {
  border-bottom: 1px solid var(--blue);
}
.drag-top {
  border-top: 1px solid var(--blue);
}
.drag-center {
  background-color: var(--blue);
}

.project-items-grid {
  position: relative;
  padding: 16px;
  border: 1px solid var(--gray_05);
  border-radius: 6px;
  align-items: center;
  width: 273px;
  height: 170px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: var(--pointer);
}
.project-items-selected {
  border-bottom: var(--border-blue_strong);
}
.project-items:active {
  cursor: grabbing;
}
.project-items-unread {
  background-color: var(--yellow_light-2);
  border-radius: 2px;
}

.project-item-name {
  width: 31.3%;
  display: flex;
  align-items: center;
}
.project-item-name input {
  cursor: var(--pointer);
  background-color: var(--white);
}
.project-item-name div {
  margin-left: 10px;
}
.project-item-count {
  color: var(--gray_01);
  width: 16.7%;
}
.project-item-info-grid {
  color: var(--gray_01);
}
.project-item-time {
  width: 31.3%;
  color: var(--gray_01);
}

.project-item-add-member {
  width: 15.8%;
  display: flex;
  align-items: center;
  line-height: 24px;
  color: var(--gray_01);
}
.project-item-add-member-pos {
  position: absolute;
  top: 18px;
  right: -8px;
}
.project-item-add-boards-pos {
  position: absolute;
  top: 0;
  right: -120px;
}
.project-item-add-member-grid {
  display: flex;
  align-items: center;
  margin-top: 24px;
  line-height: 24px;
  color: var(--gray_01);
}
.project-item-add-member-grid:hover {
  color: var(--black);
}

.project-item-add-member:hover {
  color: var(--black);
}
.project-item-plus {
  margin-left: 6.5px;
}
.project-item-members {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 22%;
}
.project-item-fav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7%;
}
.project-item-more {
  width: 6%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-item-more-grid {
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-card-icon-grid {
  width: 40px;
  height: 40px;
}
.project-more-icon-grid {
  color: '##8E8E93';
}
.project-card-menu {
  position: absolute;
  right: 5px;
  top: -5px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  width: 250px;
  height: 290;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.task-more-menu {
  display: flex;
  align-items: start;
}
.task-more-menu-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.task-more-menu-item-text {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}
.task-more-menu-shortcut-text {
  white-space: nowrap;
}
.project-card-menu-grid {
  position: absolute;
  right: 5px;
  top: -80px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  width: 159px;
  height: 290;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.relocate-card-menu-grid {
  position: absolute;
  bottom: 96px;
  right: -15px;
}
.relocate-card-menu {
  position: absolute;
  right: 5px;
  top: 18px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 286px;
  padding: 12px 0;
}
.task-select-board-menu {
  position: absolute;
  left: 0;
  top: 14px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 286px;
  padding: 5px 0;
}

.task-select-board-menu-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  padding: 2px 10px 6px 10px;
  border-bottom: solid 1px var(--gray_05);
}

.internal-note-card-menu {
  position: absolute;
  left: 0;
  align-self: center;
  margin-top: 270px;
  background-color: var(--white);
  padding: 2px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 2000;
  padding: 2px;
}
.internal-note-card-menu-wrapper {
  height: 220px;
  width: 220px;
  overflow: auto;
  padding: 10px;
  background-color: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.internal-note-container {
  width: 85%;
  margin-bottom: 5px;
}

.internal-note-num-item {
  margin-right: 10px;
  width: 20px;
  font-size: 10px !important;
}

.internal-note-bullet-item {
  margin-right: 20px;
  width: 20px;
  font-size: 10px;
}

.internal-note-text {
  width: 100%;
  margin: 0;
  height: auto;
  resize: none;
  padding: 0;
  font-size: 15px !important;
  line-height: 1.2 !important;
}

.selected-board-position-container {
  padding: 24px;
}

.project-container-position {
  padding: 16px 0;
}

.project-container-empty-shared {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
