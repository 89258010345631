/* Font Styles*/

.font-h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: var(--black);
}

.font-h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: var(--black);
}

.font-h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.005em;
  color: var(--black);
}

.font-28-reg {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: var(--black);
}

.font-24-reg {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: var(--black);
}

.font-24-med {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: var(--black);
}
.font-24-bold {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: var(--black);
}
.font-18-reg {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.014em;
  color: var(--black);
}
.font-18-med {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.014em;
  color: var(--black);
}
.font-18-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.014em;
  color: var(--black);
}
.font-16-reg {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.016em;
  color: var(--black);
}
.font-16-med {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.016em;
  color: var(--black);
}
.font-16-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.016em;
  color: var(--black);
}
.font-14-reg {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: var(--black);
}
.font-14-med {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* letter-spacing: -0.006em; */
  color: var(--black);
}
.font-14-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: var(--black);
}
.font-12-reg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
}
.font-small-reg {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: var(--black);
}
.font-12-med {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
}
.font-12-bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
}

.font-10-bold {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: var(--black);
}

.font-10-reg {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: var(--black);
}

.font-10-med {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: var(--black);
}

.font-x-small {
  font-weight: 600;
  font-size: 7px;
  line-height: 12px;
  color: var(--black);
}
.font-reg,
.font-num,
.font-point {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.016em;
  color: var(--black);
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

div[disabled] {
  pointer-events: none;
}
