.card-board-lineageView-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.card-board-lineageView-icon {
  width: 34px;
}

.card-board-lineageView-title {
  width: 100%;
  background-color: transparent !important;
}

.card-board-menu-separator-container {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 12px;
}

.card-board-menu-separator {
  background: var(--gray_05);
  width: 90%;
  height: 1px;
  align-self: center;
}

.card-board-emoji-menu {
  top: 50px;
  position: absolute;
  z-index: 20;
}

.card-board-gridView-title-container {
  transform: none;
}

.card-board-gridView-icon {
  width: 45px;
}

.card-board-gridView-favorite {
  margin-right: 5px;
}

.card-board-gridView-title {
  width: 100%;
  background-color: transparent;
  margin: 0;
  margin-top: 12px;
}

.card-board-gridView-members {
  margin-top: 15px;
}

.dashboard-header-lineAgeView {
  width: 50px;
}

.dashboard-header-card {
  width: 34px;
}

.dashboard-header-search {
  width: 60%;
  background-color: var(--white);
}

.dashboard-header-buttons {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-header-icon {
  margin-left: 5px;
  stroke: var(--white) !important;
}

.dashboard-header-buttons-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
}

.dashboard-header-board-pro {
  position: absolute;
  right: 120px;
  top: 15px;
}

.dashboard-header-project-pro {
  position: absolute;
  right: 25px;
  top: 15px;
}
.dashboard-header-sort {
  padding: 12px;
  margin-right: 8px;
  margin-left: 5px;
}

.dashboard-header-export {
  padding: 12px;
}
.dashboard-header-account {
  display: inline-flex;
  align-items: center;
}

.dashboard-header-sort-menu {
  right: 43px !important ;
}

.dashboard-header-account-name {
  margin-top: 5px;
}

.dashboard-header-account-email {
  color: var(--gray_01) !important;
  overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-header-export-tooltip {
  margin-left: -21px !important;
  top: 125% !important;
}

.dashboard-header-task {
  margin-right: 8px;
  padding-bottom: 2px;
}

.card-board-lineageView-invite {
  position: absolute;
}
