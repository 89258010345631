.account-container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  right: 0;
  left: 0;
  background-color: var(--dark-50p);
  z-index: 4000;
}

.account-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  background-color: var(--white);
  animation-duration: 200ms;
  animation-name: slideIn;
  overflow: auto;
}

.account-wrapper::-webkit-scrollbar {
  display: none;
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: var(--border-gray);
}

.account-header-tabs {
  display: flex;
}
.account-header-tab {
  margin-right: 24px;
  cursor: var(--pointer);
  color: var(--gray_01) !important;
}
.account-header-tab-active {
  color: var(--black) !important;
}

.tab-container {
  padding: 16px 24px;
}

.tab-avatar-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray_01);
  margin-left: 12px;
}

.tab-avatar-container {
  margin-top: 9px;
  display: flex;
}

.tab-account-info-container {
  padding-bottom: 24px;
  border-bottom: var(--border-gray);
}
.tab-account-info-title {
  margin: 24px 0 12px 0;
}

.tab-avatar-accent-text {
  color: var(--blue_strong) !important;
  cursor: var(--pointer);
}
.tab-avatar-danger-text {
  color: var(--coral) !important;
  cursor: var(--pointer);
}

.tab-account-rows {
  display: flex;
  justify-content: space-between;
}

.tab-account-rest {
  margin-top: 16px;
  display: flex;
}

.account-tab-forget {
  margin-top: 6px;
}

.account-tab-delete {
  margin-left: 24px;
}
