.text-editor-container {
  width: 100%;
  height: 36px;
  background-color: var(--white);
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;

  .text-editor-text-cont {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    padding: 7px 6px;
    justify-content: space-between;
    margin-right: 4px;
    width: 85px;
  }

  .text-editor-link-cont {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    padding: 7px 6px;
    justify-content: space-between;
    margin-right: 4px;
    width: 66px;
  }

  .text-editor-text-cont:hover {
    background-color: var(--gray_06);
    border-radius: 4px;
  }

  .text-editor-link-cont:hover {
    background-color: var(--gray_06);
    border-radius: 4px;
  }

  .text-editor-items-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 4px;
  }

  .text-editor-item {
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-editor-item:hover {
    background-color: var(--gray_06);
  }

  .text-editor-item-icon {
    width: 18px;
    height: 18px;
  }

  .text-editor-item-color {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 52px;
  }

  .text-editor-more-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 4px;
  }

  .text-editor-more-icon-container:hover {
    background-color: var(--gray_06);
  }

  .text-editor-separator {
    width: 1px;
    height: 80%;
    background-color: var(--gray_05);
    display: flex;
    margin: 4px 4px 4px 0;
  }

  .text-editor-more-icon {
    width: 13px;
    height: 16px;
  }
}

.text-editor-menu-container {
  background-color: var(--white);
  width: 256px;
  height: 205px;
  padding: 8px 0;
  border-radius: 4px;

  .text-editor-menu-item {
    display: flex;
    flex-flow: row nowrap;
    padding: 6px 8px;
    justify-content: space-between;
  }

  .text-editor-menu-item:hover {
    background-color: var(--gray_06);
  }

  .text-editor-menu-name {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
  }

  .text-editor-menu-key {
    color: var(--gray_0);
    display: flex;
    align-items: center;
  }

  .text-editor-menu-separator {
    width: 100%;
    height: 1px;
    background-color: var(--gray_05);
  }

  .text-editor-menu-icon {
    width: 17px;
    height: 18px;
  }

  .text-editor-menu-key-icon {
    fill: var(--gray_0);
  }
}

.text-menu-container {
  width: 248px;
  max-height: 374px;
  border-radius: 4px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;

  .text-menu-tabs-container {
    display: flex;
    flex-direction: column;
  }

  .text-menu-tabs-items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 8px;
  }

  .text-menu-tabs-name-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    align-items: center;
  }

  .text-menu-tabs-separator {
    width: 100%;
    height: 1px;
    background-color: var(--gray_06);
  }

  .text-menu-tabs-key {
    display: flex;
    align-items: center;
    color: var(--gray_0);
  }

  .text-menu-tabs-name {
    padding: 6px 0;
    color: var(--gray_0);
    transition: all 0.3s ease;
  }

  .text-menu-tabs-name-activate {
    border-bottom: 1px solid var(--black);
    color: var(--black);
  }

  .text-menu-title {
    color: var(--gray_0);
    padding: 8px;
    display: flex;
    align-items: center;
    position: sticky;
  }

  .text-menu-base-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  .text-menu-base-item-container {
    display: flex;
    flex-flow: row nowrap;
    padding: 6px 8px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
  }

  .text-menu-base-item-container:hover {
    background-color: var(--gray_06);
  }

  .text-menu-base-feature {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
  }

  .text-menu-base-key {
    color: var(--gray_0);
  }

  .text-menu-item-icon {
    width: 20px;
    height: 20px;
  }

  .toolbar-editor-icon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}

.text-editor-link-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 331px;
  height: 56px;
  border-radius: 4px;
  background-color: var(--white);

  .text-editor-link-url {
    margin: 0;
    width: 230px;
    background-color: var(--white);
  }

  .text-editor-link-url::placeholder {
    color: var(--gray_01);
  }

  .text-editor-confirm-icon {
    width: 17px;
    height: 17px;
    stroke: var(--white) !important;
  }

  .text-editor-link-confirm {
    background-color: var(--blue_strong);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 6px;
  }

  .text-editor-link-url-container {
    position: relative;
    width: 270px;
    height: 36px;
    border-radius: 4px;
    border: 2px solid var(--blue_strong);
    padding: 6px 10px;
    display: flex;
    align-items: center;
  }

  .text-editor-close-icon {
    position: absolute;
    background-color: var(--gray_0);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 3px;
    right: 6px;
    top: 8px;
    stroke: var(--white) !important;
  }
}

.text-editor-more-position {
  position: absolute;
  right: 0;
  top: -2px;
}

.text-Menu-editor-position {
  position: absolute;
  top: -2px;
}

.add-link-position {
  position: absolute;
  top: -2px;
  left: 91px;
}

.color-panel-position {
  position: absolute;
  left: 121px;
}

.active {
  background-color: var(--gray_06);
}