:root {
  --light_white: #ffffff;
  --white: #ffffff;
  --black: #000000;
  --dark-30p: rgba(0, 0, 0, 0.3);
  --dark-50p: rgba(50, 50, 50, 0.85);
  --dark-200p: rgba(200, 200, 200, 0.3);
  --dark: #3a3a3c;
  --dark_05: #636366;
  --connectors: #575555;
  --gray_0: #aeaeb2;
  --gray_01: #8e8e93;
  --gray_02: #3f3f47;
  --gray_03: #c7c7cc;
  --gray_04: #d1d1d6;
  --gray_05: #e5e5ea;
  --gray_06: #f2f2f7;
  --gray_07: #888;
  --gray_08: #ebebeb;
  --gray_09: #999;
  --lavender: #af52de;
  --lavender_light: '#F3DCFF';
  --lavender-30: rgba(175, 82, 222, 0.3);
  --purple: #5856d6;
  --purple_light: #cdccf3;
  --blue_strong: #007aff;
  --blue: #18a0fb;
  --blue_light: #b7e1fd;
  --blue_light-2: #ceeffe;
  --blue_light-3: #eff9ff;
  --green: #34c759;
  --green_light: #bdf6cc;
  --yellowgreen: yellowgreen;
  --lime: #70e852;
  --lime_light: #d4f8cb;
  --red: #ff3b30;
  --red_light: #ffc4c1;
  --pink: #ff2dd1;
  --pink_light: #f3dcff;
  --pink_strong: #ff2d55;
  --pink_strong_light: #ffc0cc;
  --coral: #ff6746;
  --coral_light: #ffd1c7;
  --orange: #ff9500;
  --orange_light: #ffdfb3;
  --yellow: #ffcc00;
  --yellow_light: #ffeca0;
  --yellow_light-2: #fff7bf;
  --button-dashboard: rgba(244, 234, 232, 1);
  --pointer: url('../assets/svg/cursor-pointer.svg'), auto;
  --grab: url('../assets/svg/cursor-grab.svg'), auto;
  --border-gray: 1px solid var(--gray_05);
  --border-blue: 1px solid var(--blue);
  --border-blue_strong: 1px solid var(--blue_strong);
  --background_light: var(--white) url('../assets/png/background.png');
  --background_dark: var(--black) url('../assets/png/background-dark.png');
}
