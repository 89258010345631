.inline-center {
  display: inline-flex;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  display: grid;
  width: 15px;
  margin: auto;
  margin-left: 4.5px;
  animation: spinner 1.5s linear infinite;
}
.margin-2 {
  margin: 2px;
}
.loading-success {
  display: grid;
  width: 15px;
  margin-left: 4.5px;
}
.loading-svg {
  fill: var(--gray_0);
}
