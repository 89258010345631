.popupMenuContainer {
  position: absolute;
  left: 0;
  align-self: center;
  margin-top: 85px;
  background-color: var(--white);
  padding: 2px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 2000;
  padding: 2px;
}
.popupMenuContainerProject {
  bottom: -45px;
}
.popupMenu {
  height: 36px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.popupMenuProject {
  margin-top: 200px;
}
.popupMenuItem {
  /* width: 34px; */
  height: 34px;
  display: flex;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  align-items: center;
  justify-content: center;
}

.popupMenuColumns {
  background-color: var(--white);
  position: absolute;
  top: -10px;
  width: 195px;
  height: 276px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.popupMenuAssignment {
  position: absolute;
  left: 275px;
  background-color: var(--white);
  width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 7px 7px 7px;
  z-index: 1000;
}

.status-menu-container {
  background-color: var(--white);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.status-menu-header {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px 6px 8px;
  border-bottom: 1px solid var(--gray_06);
}

.status-menu-text {
  white-space: nowrap;
  margin-right: 10px;
}
.vertical-menu-assign {
  left: unset;
  right: 0;
}

.popupMenuAssignmentToGo {
  left: 170px;
}
.popupMenuAssignmentAssignedMembers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.float-menu-sharing-container {
  position: absolute;
}

.popupMenuAssignmentAssignedMember {
  width: fit-content;
  border-radius: 35px;
  margin: 0 10px 7px 0;
  display: flex;
  align-items: center;
  background-color: var(--blue_light-3);
}
.popupMenuAssignmentSearchBox {
  display: flex;
  align-items: center;
  padding: 3px 7px;
  border: var(--gray_05) solid 1.5px;
  border-radius: 5px;
}

.popupMenuAssignmentSearchBoxIcon {
  fill: currentColor;
  color: var(--gray_01);
}
.popupMenuAssignmentCloseIcon {
  height: 10px;
  width: 10px;
  margin: 0px 7px 0 7px;
}
.popupMenuAssignmentMember {
  margin-top: 10px;
}
.sendOptionsWrapper {
  background-color: var(--white);
  width: 171px;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 0;
  z-index: 1000;
}
.popupMenuColorPanel {
  background-color: var(--white);
  height: 500px;
  width: 202px;
  display: flex;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;
  top: -3px;
  margin-right: 120px;
  z-index: 1000;
}

.task-board-sharing {
  position: absolute;
  left: -300px;
}

.task-board-invite {
  position: absolute;
  left: 200px;
}
.noteMenuColorPanel {
  position: absolute;
  left: -320px;
  background-color: var(--white);
  height: 32px;
  display: flex;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.statusItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  margin: 3px 0 3px 0;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}

.status-menu-placeholder {
  width: 20px;
}

.float-menu-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}

.float-menu-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}

.hover-text-pro {
  background-image: linear-gradient(
    to bottom right,
    var(--orange),
    var(--pink),
    var(--lavender),
    var(--orange)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 3px;
}

.float-menu-emoji {
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 3px;
  padding: 4px;
}

.float-menu-color {
  height: 24px;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 17px;
  border-radius: 4px;
  color: var(--gray_01) !important;
}

.float-menu-color-withoutTag {
  height: 17px;
  border-radius: 10px;
}

.popupMenuItem-Color {
  margin: 0 5px;
}

.float-menu-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3;
}

.float-menu-openMenu {
  position: absolute;
  margin-top: 35px;
}

.float-menu-emoji-container {
  position: absolute;
  top: 8px;
}

.float-menu-openMenu-status {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-menu-status-check {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4px;
}

.float-menu-openMenu-color {
  height: 100%;
  display: flex;
  align-items: center;
}

.float-menu-choice-color {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 5px;
}

.float-menu-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: auto;
}

.float-menu-search-member {
  width: 60%;
  background-color: var(--white);
  color: var(--black);
}

.float-menu-assign-container {
  padding-bottom: 5px;
}

.float-menu-assign-pro-card {
  position: absolute;
  bottom: 39px;
  right: 280px;
}

.float-menu-assign-title {
  color: var(--gray_02);
  margin-top: 10px;
  margin-bottom: 10px;
}

.float-menu-sharing {
  display: flex;
  justify-content: flex-start;
  margin-right: 72px;
  position: absolute;
  left: -8px;
}

.float-menu-sharing-item {
  width: 100%;
}

.float-menu-sharing-icon {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-menu-sharing-empty {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4px;
}

.multi-vertical-menu-search {
  width: 60%;
  background-color: var(--white);
}

.multi-vertical-menu-items {
  padding-bottom: 5px;
}

.multi-vertical-menu-recommend {
  color: var(--gray_02);
  margin-top: 10px;
  margin-bottom: 10px;
}

.float-menu-status-tooltip {
  margin-left: -22px !important;
}
