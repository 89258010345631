.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px 0 26px;
  height: 44px;
  border-bottom: 1px solid var(--gray_05);
}

input {
  width: 242px;
  height: 26px;
  margin: 0 10px;
}

.chevron {
  stroke: var(--white) !important;
  margin-left: 10px;
}

.main-menu-alarm {
  box-sizing: border-box;
  width: 16px;
  height: 17px;
}

.view-svg {
  width: 17px;
  height: 17px;
}

.header-new-project-menu {
  position: absolute;
  right: 50px;
  top: 18px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 130px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.header-new-project-items {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: 36px;
  padding: 8px;
  cursor: var(--pointer);
}
.header-new-project-items:hover {
  background: var(--gray_06);
}

.header-new-project-item {
  margin-left: 8px;
}

.header-new-project-img {
  width: 15px;
}

.header-profile-menu {
  position: absolute;
  right: 5px;
  top: -6px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 209px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.header-profile-menu-wrapper {
  width: 100%;
}

.header-profile-menu-container {
  position: absolute;
  right: 5px;
  top: 16px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 209px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.header-profile-menu-board {
  position: absolute;
  right: 4px;
  top: -5px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 209px;
  background: var(--white);
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.header-profile-menu-info {
  padding: 0px 8px;
}
.header-profile-menu-width {
  width: 100%;
}
.header-menu-avatar {
  height: 40px;
  width: 40px;
  margin: 6px 0 6px 0;
}

.header-menu-profile-items {
  display: flex;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: 36px;
  cursor: var(--pointer);
}
.header-menu-profile-items:hover {
  background: var(--gray_06);
}

.header-menu-profile-text {
  margin-left: 7px;
}

.header-menu-rest-items {
  margin-top: 12px;
}
.card-board-menu-chevron {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row-reverse;
}
