.sign-in-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-in-header {
  margin-top: 10px;
}

.svg-checked {
  animation-name: bounce;
  animation-duration: 150ms;
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
