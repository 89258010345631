.change-theme-item {
  border-radius: 10px;
  border: 1px solid var(--gray_07);
  width: 45%;
  height: 100px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: var(--pointer);
  margin: 3px;
}

.change-theme-item:hover {
  border: 1px solid var(--blue_strong);
}

.change-theme-container {
  position: fixed;
  bottom: 10px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 330px;
  border-radius: 10px;
  background-color: var(--white);
  height: 550px;
  z-index: 1000;
}

.change-theme-title {
  padding: 15px;
}

.change-theme-item-container {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  width: 100%;
}

.change-theme-icon {
  width: 14px;
  height: 13px;
}

.change-theme-image {
  border-radius: 10px;
  height: 100%;
}
