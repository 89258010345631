.smart-filter-container {
  display: flex;
  height: 30px;
  background-color: var(--white);
  border: 1px solid var(--gray_05);
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  gap: 4px;
  transition: all 0.5s;

  .smart-filter-icon {
    stroke: var(--white) !important;
  }

  .smart-filter-clear {
    background-color: var(--gray_03);
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px;
  }

  .smart-filter-name {
    color: var(--dark) !important;
    display: flex;
    flex-flow: row nowrap;
  }
}

.smart-filter-container:hover {
  border: 1px solid var(--gray_02);
}
