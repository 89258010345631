.draggableContainer {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.node-drag-on-it {
  transform: scaleX(1.1);
  transition: all 0.1s ease;
  transform-origin: left;
}

.user-editor {
  position: absolute;
  left: 7px;
  margin-top: -48px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--blue_strong);
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.member-list-add {
  color: var(--blue_strong);
}

.member-list-items {
  justify-content: space-between;
}

.member-list-avatar {
  margin-left: 8px;
}

.member-list-delete {
  margin-right: 10px;
}

.member-loading-container {
  display: grid;
  width: 15px;
  margin: auto;
  margin-left: 4.5px;
  animation: spinner 1.5s linear infinite;
  width: 24px;
  height: 24px;
  margin: auto;
}
