.tariff-container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 62px 0;
  align-items: center;
  gap: 12px;

  .tariff-close-container {
    width: 36px;
    height: 36px;
    padding: 2px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 4px;
    right: 4px;
  }

  .tariff-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .tariff-offer-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .tariff-logo {
    height: 36px;
    width: 36px;
  }

  .tariff-offer {
    display: flex;
    flex-flow: row nowrap;
    gap: 21px;
  }

  .gradient-border {
    background-origin: border-box;
    background-clip: border-box;
    background-image: linear-gradient(
      to bottom left,
      var(--pink),
      var(--orange),
      var(--orange),
      var(--orange)
    );
    border: 4px solid transparent;
    border-radius: 8px;
  }

  .text-price-enterprise {
    background-image: linear-gradient(
      to bottom right,
      var(--yellow),
      var(--yellow),
      var(--blue_strong),
      var(--blue_strong),
      var(--green)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .tariff-item-container {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 379px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tariff-border {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .tariff-container-border {
    border: 3px solid var(--gray_03);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tariff-item-title-price {
    color: var(--pink);
  }

  .tariff-item-title-price-margin {
    margin: 24px 8px 32px 0;
  }

  .tariff-item-title {
    margin: 27px 0 32px 0;
  }

  .tariff-item-title-before {
    margin-top: 30px;
  }

  .checkMark-tariff-icon {
    stroke: var(--blue_strong);
  }

  .tariff-active-button {
    display: flex;
    gap: 6px;
    border-radius: 10px;
    width: 315px;
  }

  .tariff-feature-text {
    padding: 20px 0 24px 0;
    color: var(--blue_strong);
  }

  .tariff-feature-text-pro {
    padding: 20px 0 24px 0;
    text-align: center;
    margin-bottom: 145px;
  }

  .tariff-feature-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 8px 0;
  }

  .tariff-feature-gift {
    color: var(--gray_01);
  }

  .tariff-feature-item {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;
  }

  .tariff-limit-icon {
    width: 17px;
    height: 17px;
  }

  .ref-plans:link {
    color: var(--black);
  }

  .tariff-limit-icon-infinity {
    width: 22px;
    height: 22px;
  }

  .tariff-sponsor-container {
    width: 379px;
    background-color: var(--orange_light);
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    padding: 20px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
  }

  .tariff-pro-title {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    height: 105px;
  }

  .tariff-act-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tariff-feature-md-margin {
    margin-top: 24px;
  }

  .tariff-pro-tag {
    height: 38px;
    width: 40%;
    border: 1px solid var(--gray_04);
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 4px;
    border-radius: 10px;
    justify-content: center;
  }

  .AI-tag {
    opacity: 0.4;
  }

  .tariff-pro-subject {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tariff-choose-plan-contactUs {
    color: var(--white);
    background-color: var(--blue_strong);
    display: flex;
    gap: 6px;
    width: 315px;
    height: 44px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all 0.3s;
  }

  .tariff-choose-plan-contactUs:hover {
    background-color: var(--black);
  }

  .tariff-choose-plan-contactUs:hover .logo-contact-us-icon {
    fill: var(--white);
  }

  .logo-contact-us-icon {
    fill: var(--yellow);
    width: 20px;
    height: 20px;
  }

  .tariff-pro-tag-tooltip {
    left: 43% !important;
    width: 93px;
  }

  .tariff-pro-tag-tooltip-color {
    color: var(--orange);
  }

  .tariff-pro-tag-tooltip-color::before {
    content: ' ';
  }
}

.pro-option-container {
  display: flex;
  flex-direction: column;
  height: 227px;
  width: 222px;
  background-color: var(--white);
  gap: 8px;
  border-radius: 8px;

  .pro-option-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid var(--gray_05);
  }

  .pro-option-header-close {
    color: var(--gray_01);
  }

  .pro-option-logo {
    background-image: url('../assets/png/pro-logo.png');
    width: 115px;
    height: 31px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }

  .pro-option-logo-container {
    display: flex;
    justify-content: center;
  }

  .pro-option-text {
    min-height: 76px;
    text-align: center;
  }

  .pro-option-description {
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-image: radial-gradient(
      207px 189px at 202px top,
      var(--pink_light),
      var(--purple_light),
      var(--orange_light)
    );
    border-radius: 8px;
    margin: 0 8px;
    gap: 5px;
    min-height: 167px;
  }

  .pro-option-button {
    background-image: linear-gradient(
      to bottom right,
      var(--orange),
      var(--pink),
      var(--lavender),
      var(--orange)
    );
    color: var(--white);
    border-radius: 6px;
    height: 36px;
    width: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pro-option-button-container {
    display: flex;
    justify-content: center;
  }
}

.tariff-choose-plan {
  background-image: linear-gradient(
    to bottom right,
    var(--orange),
    var(--pink),
    var(--lavender),
    var(--orange)
  );
  color: var(--white);
  width: 315px;
  height: 44px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

.tariff-tag-product {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tariff-choose-plan:hover {
  background-color: var(--black);
  background-image: none;
  position: relative;
}

.tariff-choose-plan:hover::before {
  content: '';
  background-image: linear-gradient(
    to bottom right,
    var(--orange),
    var(--pink),
    var(--lavender),
    var(--orange)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.tariff-choose-plan:hover span {
  color: transparent;
  background-image: linear-gradient(
    to bottom right,
    var(--orange),
    var(--pink),
    var(--lavender),
    var(--orange)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
