.nodeStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 2px;
  box-sizing: border-box;
  padding: 0px 7px;
  border: 2px solid transparent;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
}

.nodeHoverTop {
  border-top: 2px solid var(--orange) !important;
}
.nodeHoverBottom {
  border-bottom: 2px solid var(--orange) !important;
}
.nodeHoverCenter {
  border: 2px solid var(--orange) !important;
}
.nodeFolder {
  border: var(--gray_01) 1px dashed;
}
.nodeFolder:hover {
  border: var(--black) 1px dashed;
}
.nodeFolderFocus {
  border: var(--blue_strong) 1px dashed;
}
.nodeInput {
  text-align: left;
  border-width: 0;
  background-color: transparent;
  outline: 0 none;
  /* min-width: 105px; */
  min-width: 10px;
}
.nodeProject {
  min-width: 77px;
  min-height: 70px;
  box-sizing: border-box;
  /* min-width: 135px; */
  height: calc(100% - 4px);
}

.node-container {
  border: 1.5px solid transparent;
}
.node-container-focused {
  border: 1.5px solid var(--blue_strong);
}
.node-container:hover {
  box-sizing: border-box;
  border: 1.5px solid var(--dark-30p);
}

.assignment-other-users-first-level {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 3px;
  text-align: center;
  background-color: var(--white);
  height: 23px;
  width: 23px;
  border-radius: 5px;
  color: var(--dark);
}
.assignment-other-users-second-level {
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 3px;
  text-align: center;
  background-color: var(--white);
  height: 16px;
  width: 16px;
  border-radius: 3px;
  color: var(--dark);
}
.assignment-other-users-third-level {
  font-size: 7px;
  font-weight: 500;
  line-height: 12px;
  margin-left: 3px;
  text-align: center;
  background-color: var(--white);
  height: 12px;
  width: 12px;
  border-radius: 2px;
  color: var(--dark);
}
.avatarAssignmentDivider {
  width: 1px;
  margin: 0 7px;
  height: 18px;
  border-radius: 10px;
  background-color: var(--black);
}
.plus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--blue_strong);
  cursor: var(--pointer);
  right: -26px;
}
.plus-btn:hover {
  border: 2px solid var(--dark-30p);
  box-sizing: border-box;
}

.plus-white {
  stroke: var(--white) !important;
}
#hide {
  position: absolute;
  opacity: 0;
  z-index: -100;
  margin-top: 20px;
  /* word-break: break-all; */
  /* white-space: pre; */
}
.dragged {
  background-color: var(--dark-p200);
  opacity: 0.5;
  /* cursor: var(--pointer) !important; */
}

.board-status {
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.board-status-36 {
  width: 36px;
  height: 36px;
  border-radius: 7px;
}
.board-status-28 {
  width: 28px;
  height: 28px;
  border-radius: 6px;
}
.board-status-22 {
  width: 22px;
  height: 22px;
  border-radius: 4px;
}
.board-status-icon-26 {
  height: 17px;
  width: 17px;
}
.board-status-icon-28 {
  height: 14px;
  width: 14px;
}
.board-status-icon-22 {
  height: 12px;
  width: 12px;
}
.board-status-icon-menu {
  position: absolute;
  top: 15px;
  left: -30px;
}
.BoardNoteIcon-first-level {
  fill: black;
  width: 16px;
  height: 20px;
}
.BoardNoteIcon-second-level {
  fill: black;
  width: 14px;
  height: 18px;
}
.BoardNoteIcon-third-level {
  fill: black;
  width: 12px;
  height: 14px;
}
.BoardNoteIconForFolder-first-level {
  fill: var(--black);
  width: 16px;
  height: 20px;
}
.BoardNoteIconForFolder-second-level {
  fill: var(--black);
  width: 14px;
  height: 18px;
}
.BoardNoteIconForFolder-third-level {
  fill: var(--black);
  width: 12px;
  height: 14px;
}
.commentIcon-first-level {
  color: var(--gray_01);
  width: 16px;
  height: 20px;
}
.commentIcon-second-level {
  fill: var(--gray_01) !important;
  width: 12px;
  height: 18px;
}
.commentIcon-third-level {
  fill: var(--gray_01) !important;
  width: 10px;
  height: 14px;
}
.commentIcon-level {
  width: 18px;
  height: 18px;
}
.budget-comments {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  right: -5px;
  top: -5px;
  color: var(--white) !important;
  background-color: var(--lavender);
}
.budget-comments-first-level {
  font-size: 9px;
  width: 14px;
  height: 14px;
}
.budget-comments-second-level {
  font-size: 8px;
  width: 11px;
  height: 11px;
  top: -4px;
}
.budget-comments-third-level {
  font-size: 7px;
  width: 9px;
  height: 9px;
  top: -2px;
}
.focus-mode-back {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 4px;
  margin: 0 4px;
  background-color: var(--blue_strong);
  align-self: center;
  cursor: var(--pointer);
}
.focus-mode-back-svg {
  fill: var(--white) !important;
}
.deadline {
  margin-left: 5px;
}
.deadline-close {
  color: var(--red);
}
.deadline-normal {
  color: var(--black);
}
.deadline-long {
  color: var(--gray_01);
}
.tree-node-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tree-node-parent {
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 2.5px;
  margin-top: 2.5px;
  height: 100%;
}
.tree-node-children {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* z-index: 1; */
}
.tree-node-multi-select {
  height: 17px;
  width: 17px;
  margin-right: 6px;
}
.tree-node-name {
  width: 100%;
  justify-content: center;
}
.tree-node-show-emoji {
  /* height: 24px; */
  margin-right: 5px;
}
.tree-node-show-task {
  display: inline-block;
}
.tree-node-title {
  padding: 0;
  font-family: inherit;
  resize: none;
  line-height: 2;
  max-width: 600px;
  color: black !important;
}
.tree-node-title-folder {
  color: var(--black) !important;
}

.tree-node-first-level {
  line-height: 24px;
}
.tree-node-second-level {
  line-height: 16px;
}
.tree-node-third-level {
  line-height: 14px;
}
.tree-node-first-level-height {
  height: 36px;
  border-radius: 7px;
}
.tree-node-second-level-height {
  height: 28px;
  border-radius: 6px;
}
.tree-node-third-level-height {
  height: 22px;
  border-radius: 4px;
}
.tree-node-has-note {
  position: relative;
  display: flex;
  margin: 0 5px;
  margin-right: 0;
}
.tree-node-assignment {
  margin-left: 5px;
}
.tree-node-point-project {
  position: absolute;
  right: 2px;
  background-color: var(--dark);
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.tree-node-spreader {
  position: absolute;
  right: -60px;
  width: 60px;
  height: 50px;
}
.tree-node-collapse-icon {
  color: var(--white);
  display: flex;
  align-self: center;
}
.tree-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.tree-parent {
  display: flex;
  flex-direction: row;
}
