.calendar-container {
  width: 272px;
  background-color: var(--white);
  padding: 8px;
  border-radius: 5px;
  z-index: 1000;
}
.calendar-grid {
  display: grid;
  grid-template: repeat(7, auto) / repeat(7, auto);
  margin-bottom: 16px;
}
.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.calendar-header-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  cursor: var(--pointer);
}
.calendar-date-same-month {
  color: var(--black);
}
.calendar-days {
  margin-bottom: 7px;
  color: var(--gray_01);
}
.calendar-day {
  padding: 5px;
  text-align: center;
  border-radius: 2px;
  cursor: var(--pointer);
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}
.calendar-day-today {
  background-color: var(--green_light);
  border-radius: 4px !important;
}
.calendar-date {
  color: var(--gray_01);
}
.highlighted-calendarDate {
  background-color: var(--blue_strong) !important;
  color: var(--white);
  border-radius: 4px !important;
  /* width: 28px; */
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.highlighted-calendarDate-range {
  opacity: 0.6;
  background-color: var(--blue_strong) !important;
  color: var(--white);
  border-radius: 0 !important;
  /* width: 28px; */
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-tail {
  margin-top: 12px;
}
.calendar-input {
  width: 80px;
  height: 25px;
  margin-right: 0;
  padding: 4px;
  border: 1px solid var(--blue);
  border-radius: 3px;
  background-color: var(--white);
  color: var(--black);
}
.calendar-input-time {
  display: flex;
  height: 17px;
  width: 1w-20;
  width: 18px;
  padding: 0px;
  margin: 0px;
  background-color: var(--white);
  color: var(--black);
}
.calendar-time-container {
  position: relative;
}
.calendar-reminder-selector {
  display: flex;
  align-items: center;
  background-color: var(--white);
  color: var(--black);
  padding: 4px;
  margin-bottom: 7px;
  cursor: var(--pointer);
}

.calendar-reminder-selector-text {
  width: 236px;
  text-align: center;
}
.calendar-reminder-container {
  border-bottom: 1px solid var(--gray_06);
}
.calendar-reminder-selector-items {
  height: 216px;
  width: 266px;
  background-color: var(--white);
  border-radius: 4px;
  padding: 4px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  left: 0;
  top: 270px;
}

.calendar-reminder-selector-item {
  padding: 2px;
  cursor: var(--pointer);
  color: var(--black);
}
.calendar-reminder-selector-item:hover {
  color: var(--black);
}
.calender-reminder-check-box {
  display: flex;
  width: 17px;
  height: 17px;
  cursor: var(--pointer);
}
.w-20 {
  width: 20px;
}
.w-25 {
  width: 25px;
}
.calendar-more-month-icon {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
}

.calendar-header-more-mounth-menu {
  background-color: var(--white);
  position: absolute;
  top: 48px;
  right: 42px;
  height: 216px;
  min-width: 165px;
  overflow: scroll;
}

.calendar-more-month-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 7px;
  align-items: center;
}

.calendar-header-more-item {
  padding: 3px 8px;
  width: 100%;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.calendar-input-start-date-container {
  width: 100%;
  border: 1px solid var(--gray_01);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-input-start-date {
  outline: none;
  width: 80%;
  margin: 2px 0 2px 8px !important;
  background-color: var(--white);
}

.calendar-input-start-date::placeholder {
  color: var(--gray_01);
}

.deleteValue-icon {
  background-color: var(--gray_0);
  padding: 3px;
  width: 16px;
  height: 16px;
  stroke: var(--white) !important;
  border-radius: 50%;
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.calendar-dateRange-select {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
}

.calendar-dateRange-select-container {
  border-top: 1px solid var(--gray_04);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  width: 100%;
  padding-top: 15px;
}

.calendar-dueDate-input-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  gap: 2px;
}

.calendar-input-dueDate-wrapper {
  width: 122px;
  height: 36px;
  border: 1px solid var(--gray_01);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-separator-inputs {
  color: var(--gray_01);
  display: flex;
  align-items: center;
}

.calendar-hover-inputs:hover {
  border: 1px solid var(--gray_02);
}

.calendar-dueDate-time-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendar-included-time-container {
  display: flex;
  width: 80%;
  margin: 2px 0 2px 8px !important;
  background-color: var(--white);
  padding: 1px 2px;
}

.calendar-clear-all {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.calendar-clear-all-color {
  color: var(--gray_01) !important;
}

.calendar-input-time-semi {
  margin-top: -2px !important;
}
