.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 10000;
  top: 140%;
  left: 50%;
  margin-left: -29px;
  padding: 4px 6px;
}

.tooltip .tooltiptext::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--black) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipLeft {
  position: relative;
}

.tooltipLeft .tooltiptextLeft {
  visibility: hidden;
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 10000;
  padding: 3px 7px;
  visibility: hidden;
  width: 70px;
  top: 0px;
  right: 155%;
}

.tooltipLeft .tooltiptextLeft::after {
  content: '';
  position: absolute;
  bottom: 30%;
  left: 50%;
  margin-left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--black);
}

.tooltipLeft:hover .tooltiptextLeft {
  visibility: visible;
}
