.control-tab-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  background-color: var(--white);

  .control-tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: var(--gray_01);
    padding: 4px 6px;
    transition: all 0.2s;
    gap: 3px;
  }

  .control-tab-item:hover {
    color: var(--black);
  }

  .control-tab-item-selected {
    background-color: var(--gray_06);
    color: var(--black);
  }
}
