.vertical-menu-container {
  position: fixed;
  right: 10px;
  top: 30%;
  transform: translateX(150%);
  -webkit-transform: translateX(150%);
  z-index: 100;
}

.slide-in,
.slide-out {
  transition: margin-right 0.4s ease-in;
  -webkit-transition: margin-right 0.4s ease-in;
}

.slide-in {
  background-color: var(--white);
  margin-right: 67px;
}

.slide-out {
  background-color: var(--white);
  margin-right: 0;
}

.menu-item-container {
  position: absolute;
  bottom: 37px;
  right: 102px;
  background-color: var(--white);
  width: 153px;
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.vertical-menu-popupMenuItem {
  width: 38px;
  height: 38px;
  margin: 6px 0;
  display: flex;
  cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  align-items: center;
  justify-content: center;
}

.verticalMenuColorPanel {
  position: absolute;
  right: 37px;
  bottom: -95px;
  background-color: var(--white);

  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}
.vertical-menu-wrapper {
  flex-direction: column;
  height: undefined;
}
.vertical-menu-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}
.vertical-menu-status-container {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical-menu-status-check {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 4px;
}
.vertical-menu-date {
  position: absolute;
  right: 37px;
  bottom: -178px;
}
.deadLine-pro-card-pos {
  position: absolute;
  bottom: 38px;
  right: 37px;
}
.vertical-menu-color-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.vertical-menu-color-item {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 5px;
}
.vertical-menu-color-check {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: auto;
}
.vertical-menu-emoji {
  position: absolute;
  right: 45px;
  bottom: -50px;
}
.vertical-menu-assignment {
  position: absolute;
  right: 37px;
  bottom: 271px;
  margin-top: 0;
}

.vertical-menu-icon {
  width: 18px;
  height: 18px;
}
