.sidebar-container {
  /* width: 240px; */
  /* height: 100vh; */
  position: relative;
  border-right: 1px solid var(--gray_05);
  background-color: var(--white);
  /* min-width: 180px; */
}

.sidebar-container-layer-icon {
  margin: 6px;
}

.sidebar-container-menu-icon {
  width: 18px;
  height: 18px;
}

.sidebar-container-menu-upper {
  margin-top: 2px;
}

.sidebar-container-menu-items {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 13px;
  cursor: url('../../../assets/svg/cursor-pointer.svg'), auto;
  width: 240px;
}
.sidebar-container-menu-items-delete {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 13px;
  cursor: url('../../../assets/svg/cursor-pointer.svg'), auto;
  width: 240px;
  position: fixed;
  bottom: 0;
}
.sidebar-container-menu-items-selected {
  background-color: var(--blue_light-2);
}
.sidebar-container-menu-items:hover {
  background-color: var(--blue_light-2);
}
.sidebar-container-menu-text {
  margin-left: 11px;
}
.sidebar-pro-tab-button {
  background-image: linear-gradient(
    to bottom right,
    var(--orange),
    var(--pink),
    var(--lavender),
    var(--orange)
  );
  color: var(--white) !important;
  border-radius: 6px;
  height: 24px;
  width: 37px;
  margin-left: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.sidebar-container-menu-items-pro {
  border-top: 1px solid var(--gray_05);
  padding: 28px 13px;
}
.sidebar-favorite-title {
  color: var(--gray_01) !important;
  margin: 12px;
  margin-bottom: 0;
}
.sidebar-favorite-section-title {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: clip;

  max-width: 85%;
}
.sidebar-favorite-title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 0;
}

.sidebar-favorite-title-icon {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.sidebar-favorite-title-icon-up {
  transform: rotate(180deg);
  transition: all 0.2s;
}
.sidebar-h-divider {
  margin-top: 2px;
  border-bottom: 1px solid var(--gray_05);
}
.dropdown-divider {
  margin-top: 12px;
  border-bottom: 1px solid var(--gray_03);
}
.sidebar-board-icon {
  height: 20px;
}

.budget-shared {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  color: white !important;
  background-color: var(--lavender);
}

/* .empty-sidebar-caontainer {
  width: 240px;
} */
