.chevron-black {
  stroke: var(--black);
}

.chevron-white {
  stroke: var(--white);
}

.black-icon-fill {
  fill: var(--black);
}