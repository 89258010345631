.section {
  min-width: 400px;
  text-align: center;
}

.boardMainContainer {
  background: var(--background_light);
}

.boardMainDarkContainer {
  background: var(--background_dark);
}
.boardMainContainerOutlineView {
  background: null;
}
#scrollable-container {
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
}
#scrollable-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--gray_03);
}

#scrollable-container::-webkit-scrollbar-thumb {
  /* background-color: var(--gray_03); */
  background-color: var(--gray_01);
  border-radius: 100px;
}

.main-container {
  position: absolute;
  top: 45px;
  left: 3px;
  margin: 0;
  width: fit-content;
}

.main-container-position {
  transition: all 0.1s;
}

.outline-container {
  margin-top: 45px;
  margin-left: 10px;
  overflow: scroll;
}
.main-container-taskView {
  width: 100% !important;
}

.tree {
  z-index: 2;
}

.tree-togo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
}
.arrow {
  z-index: 1;
}

.content-container {
  height: 100%;
  width: 400px;
  margin: auto;
  background-color: var(--white);
  opacity: 0.5;
}

#scroll-empty-space {
  float: right;
  height: 100%;
  background-color: var(--black);
}

#navbar-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  /* width: 100vw; */
  background-color: var(--yellowgreen);
  z-index: 2000;
}

#navbar-section .content-container {
  display: inline-block;
}

#header-section {
  background-color: #ff7a85;
  height: 110px;
}

#data-section {
  background-color: #62c4c4;
  height: 550px;
}

#footer-section {
  background-color: #fced05;
  height: 110px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
}
