.filter-tag-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 220px;
  border-radius: 4px;

  .filter-tag-name {
    padding: 6px;
  }

  .filter-tag-separate {
    width: 100%;
    height: 1px;
    background-color: var(--gray_04);
  }

  .filter-tag-item-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 6px 8px;
    gap: 6px;
  }

  .filter-tag-item-name {
    color: var(--gray_01);
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    padding: 2px 8px;
  }

  .filter-tag-item-name:hover {
    color: var(--black);
  }
}
