.outline-view-node-container {
  background-color: 'transparent';
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 7px;
}

.outline-view-node-container:hover {
  background-color: var(--gray_06);
}

.outline-header {
  text-align: center;
  margin: 10px;
  color: var(--connectors);
}

.outline-view-node-container:hover .outline-view-node-handle {
  display: block;
  cursor: grab;
}

.outline-view-node-style {
  margin-left: 12px;
  padding: 0px 0 3px 12px;
  border-left: 1px solid var(--gray_08);
  padding-left: 10px;
}

.taskView-view-node-style {
  padding-left: 10px;
}
.outline-view-node {
  background-color: transparent;
  border-bottom: transparent solid 1px;
  width: 100%;
  padding-right: 5px;
}

.outline-view-node:hover .outline-view-node-dot {
  background: var(--lavender);
}

.outline-view-node:hover .outline-view-node-arrow {
  border-left: 6px solid var(--lavender);
}

.outline-view-node-is-hovered {
  border-bottom: var(--blue_strong) solid 1px;
}

.outline-view-node-handle {
  display: none;
}

.outline-view-node-handle-svg {
  padding-top: 3px;
  width: 18px;
  height: 18px;
}

.outline-view-node-input {
  width: 100%;
  background-color: transparent;
  padding: 15px 0;
}

.outline-view-node-arrow {
  cursor: var(--pointer);
  top: 2px;
  left: 5px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid var(--gray_09);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition:
    transform 0.2s,
    -webkit-transform 0.2s;
}

.outline-view-node-arrow-expanded {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.outline-view-node-dot {
  top: 3px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--gray_09);
}

.outLine-container {
  width: 99vw;
  padding: 10px;
  background-color: var(--white);
}

.outLine-show-child {
  padding: 5px;
}

.task-view-container {
  gap: 0 !important;
  width: 100%;
}
