.menu-member-search {
  background-color: transparent;
}

.menu-member-items {
  justify-content: space-between;
}

.menu-member-avatar {
  margin-left: 8px;
}

.menu-member-accounts {
  margin-right: 10px;
}

.menu-move-container {
  margin-left: 10px;
}

.menu-move-create {
  padding: 0 !important;
  height: 200px;
}

.menu-move-create-text {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-move-create-button {
  width: 40%;
  display: flex;
  float: right;
  justify-content: space-between;
  margin: 20px;
}

.menu-move-wrapper {
  padding: 0 !important;
  height: 500px;
}

.menu-move-text-limit {
  margin-left: 5px;
}

.menu-move-title {
  margin-right: 8px;
}

.menu-move-folder-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-move-folder-text {
  margin: 10px;
}

.menu-move-create-folder-container {
  justify-content: space-between;
  padding: 20px;
}

.menu-move-create-folder-text {
  text-decoration: underline;
  text-decoration-color: var(--gray_01);
}

.menu-move-create-folder-button {
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.modal-member-list-invite {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  padding: 6px 8px;
}

.invite-member-pro-pos {
  position: absolute;
  bottom: -30px;
  left: -220px;
}

.modal-member-list-invite-container {
  display: flex;
  flex-direction: column;
}
