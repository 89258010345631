/* topToDown */
.topToDown-enter {
  position: relative;
  right: 0;
  top: 0px !important;
  opacity: 0;
}
.topToDown-enter-done {
  position: relative;
  transition: all 0.2s;
  opacity: 1;
  z-index: 10000;
  top: 8px;
}
.topToDown-exit {
  position: relative;
  top: 0 !important;
  transition: all 0.2s;
  opacity: 0;
  z-index: 10000;
}
/* downToUp */
.downToUp-enter {
  position: relative;
  bottom: 0px !important;
  opacity: 0;
}
.downToUp-enter-done {
  position: relative;
  transition: all 0.2s;
  opacity: 1;
  z-index: 10000;
  bottom: 8px;
}
.downToUp-exit {
  position: relative;
  bottom: 0 !important;
  transition: all 0.2s;
  opacity: 0;
}
/* rightToLeft */
.rightToLeft-enter {
  position: relative;
  right: 0px !important;
  opacity: 0;
}
.rightToLeft-enter-done {
  position: relative;
  transition: all 0.2s;
  opacity: 1;
  z-index: 10000;
  right: 8px;
}
.rightToLeft-exit {
  position: relative;
  right: 0 !important;
  transition: all 0.2s;
  opacity: 0;
}
/* leftToRight */
.leftToRight-enter {
  position: relative;
  left: 0px !important;
  opacity: 0;
}
.leftToRight-enter-done {
  position: relative;
  transition: all 0.2s;
  opacity: 1;
  z-index: 10000;
  left: 8px;
}
.leftToRight-exit {
  position: relative;
  top: 0 !important;
  transition: all 0.2s;
  opacity: 0;
}
