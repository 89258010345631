.check-list-container {
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 5px;

  .check-list-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  .check-list-title-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .check-list-title-name {
    display: flex;
    flex-flow: row nowrap;
    gap: 7px;
    align-items: center;
    background-color: var(--white);
  }

  .check-list-title-name-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 7px;
    align-items: center;
    width: 55%;
    min-width: 300px;
  }

  .check-box-title-feature {
    display: flex;
    flex-flow: row nowrap;
    gap: 18px;
    align-items: center;
  }

  .check-list-title-date {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    min-width: 135px;
  }

  .check-list-title-date-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    width: 20%;
  }

  .check-list-title-date:hover .check-list-title-date-icon {
    stroke: var(--black) !important;
  }

  .check-list-title-date:hover .check-list-title-date-text {
    color: var(--black) !important;
  }

  .check-list-title-date-icon {
    stroke: var(--gray_01) !important;
  }

  .check-list-title-date-container:hover .check-list-title-date-icon {
    stroke: var(--black) !important;
  }
  .check-list-title-date-text {
    color: var(--gray_0) !important;
  }

  .check-list-title-date-container:hover .check-list-title-date-text {
    color: var(--black) !important;
  }

  .check-list-title-member-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    width: 20%;
  }

  .check-list-title-member {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    padding: 5px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  }

  .check-list-title-wrapper:hover .check-list-title-more-icon {
    visibility: visible;
    fill: var(--gray_01);
  }

  .check-list-title-more-icon {
    visibility: hidden;
  }

  .check-list-title-more-menu {
    position: absolute;
    top: 10px;
    right: -6px;
  }

  .check-list-title-more {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    justify-content: center;
  }

  .check-list-title-member-more {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray_06);
    padding: 6px 4px;
    border-radius: 6px;
    width: 35px;
    height: 24px;
    color: var(--dark_05);
  }

  .check-list-progress-container {
    width: 100%;
    height: 8px;
    background-color: var(--gray_06);
    border-radius: 10px;
  }

  .check-list-display-progress {
    border-radius: 10px;
    height: 8px;
  }

  .check-list-card-check {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid var(--gray_03);
    background-color: var(--white);
  }

  .check-list-card-selected {
    background-color: var(--blue_strong) !important;
    border: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-list-card-selected-icon {
    stroke: var(--white) !important;
  }

  .check-list-card-name {
    margin-left: 0;
    width: 70%;
    min-width: 300px;
  }

  .check-list-card-name-checked {
    text-decoration-line: line-through;
  }

  .check-list-card-name::placeholder {
    color: var(--gray_03);
    margin-left: 0;
  }

  .check-list-card-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 8px;
    align-items: center;
  }

  .check-list-todo-member-menu {
    position: absolute;
    left: 140px;
    top: 10px;
  }

  .check-list-card-name {
    display: flex;
    flex-flow: row nowrap;
    gap: 7px;
    align-items: center;
  }

  .check-box-card-feature {
    display: flex;
    flex-flow: row nowrap;
    gap: 18px;
    align-items: center;
    width: 20%;
  }

  .check-list-card-date {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    width: 20%;
    position: relative;
  }

  .check-list-card-date-icon {
    stroke: var(--dark-50p) !important;
  }

  .check-list-card-date-text {
    color: var(--dark-50p) !important;
  }

  .check-list-card-member-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
  }

  .check-list-card-member {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    padding: 5px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  }

  .check-list-card-container:hover {
    background-color: var(--gray_06);
  }

  .check-list-card-container:hover .check-list-card-name {
    background-color: var(--gray_06);
  }

  .check-list-card-container:hover .check-list-card-more-icon {
    visibility: visible;
    fill: var(--gray_01);
  }

  .check-list-card-more-icon {
    visibility: hidden;
  }

  .check-list-card-more-menu {
    position: absolute;
    top: 10px;
    right: -12px;
  }

  .check-list-card-more {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    justify-content: center;
  }

  .check-list-card-more:hover .check-list-card-more-icon {
    fill: var(--black) !important;
  }

  .check-list-card-member-more {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray_06);
    padding: 6px 4px;
    border-radius: 6px;
    width: 35px;
    height: 24px;
    color: var(--dark_05);
  }

  .check-list-title-calendar {
    position: absolute;
    left: 5px;
    top: 20px;
  }

  .check-list-title-member-menu {
    position: absolute;
    right: 158px;
    top: 10px;
  }

  .check-list-card-member-menu {
    position: absolute;
    right: -120px;
    top: 6px;
  }

  .check-list-more-menu-calendar {
    position: absolute;
    left: -270px;
    top: -206px;
  }

  .check-list-more-menu-members {
    position: absolute;
    left: -280px;
    top: -162px;
    // bottom: 0;
  }
}

.check-list-menu-container {
  display: flex;
  width: 256px;
  // height: 205px;
  background-color: var(--white);
  flex-direction: column;
  border-radius: 4px;

  .check-list-menu-item-container {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px;
  }

  .check-list-menu-item-container:hover {
    background-color: var(--gray_06);
  }

  .check-list-menu-name-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
    align-items: center;
  }

  .check-list-menu-name-icon {
    width: 17px !important;
    height: 17px !important;
  }

  .check-list-menu-keyName {
    color: var(--gray_02);
  }

  .check-list-menu-keyName-icon {
    fill: var(--gray_02) !important;
  }

  .check-list-menu-separator {
    width: 100%;
    height: 1px;
    background-color: var(--gray_05);
  }
}

.todo-list-container {
  margin-top: 15px;
}
