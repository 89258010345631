.confirm-container {
  display: flex;
  flex-direction: column;
  min-height: 130px;
  gap: 20px;
  justify-content: space-between;
  padding: 20px;

  .confirm-delete-buttons {
    display: flex;
    gap: 10px;
  }
}

.confirm-modal-close {
  display: flex;
  justify-content: end;
}
