.dashboard-recent-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--white);
  width: 100%;
  gap: 24px;

  .recent-select-board-text {
    color: var(--white);
  }

  .tape-template-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }

  .tape-template-card-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    width: 100%;
    height: 169px;
    align-items: center;
  }

  .dashboard-recent-show-temp {
    color: var(--blue_strong);
  }

  .card-template-container {
    display: flex;
    flex-direction: column;
    padding: 16px 12px 18px 16px;
    width: 25%;
    min-width: 185px;
    border: 1px solid var(--gray_05);
    border-radius: 6px;
    gap: 10px;
    min-height: 169px;
  }

  .card-template-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .card-template-title-more {
    width: 20px;
  }

  .card-template-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3px;
  }

  .card-template-content {
    color: var(--gray_01);
  }

  .card-template-number {
    color: var(--gray_01);
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .tape-template-right-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 32px;
    background-color: var(--blue_strong);
    border-radius: 6px;
  }

  .tape-template-right-slide:hover {
    border: 1px solid var(--black);
  }

  .tape-template-right-icon {
    fill: var(--white) !important;
  }

  .tape-template-left-icon {
    fill: var(--white) !important;
    transform: rotate(180deg);
  }
}

.recent-select-board-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .recent-select-board {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
  }

  .recent-create-board-button {
    padding: 26px 12px 30px 12px;
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 25%;
    min-width: 262px;
    height: 80px;
    background-color: var(--purple);
  }

  .recent-create-project-button {
    position: relative;
    padding: 26px 12px 30px 12px;
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 25%;
    min-width: 262px;
    height: 80px;
    background-color: var(--blue);
  }

  .recent-select-board-wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 1px dashed var(--gray_0);
    border-radius: 4px;
    width: 50%;
    height: 80px;
  }

  .recent-select-board-wrapper:hover {
    border: 1px dashed var(--blue_strong);
  }

  .recent-select-board-button-container {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 37px;
  }

  .recent-select-board-icon {
    fill: var(--white) !important;
  }

  .recent-select-board-button {
    background-image: linear-gradient(
      to bottom right,
      var(--orange),
      var(--pink),
      var(--lavender),
      var(--orange)
    );
    color: var(--white);
    border-radius: 6px;
    height: 36px;
    width: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 117px;
    height: 36px;
    min-width: 106px;
    gap: 5px;
  }

  .recent-select-board-button:hover {
    background-color: var(--black);
    background-image: none;
    position: relative;
  }

  .recent-select-board-button:hover::before {
    content: '';
    background-image: linear-gradient(
      to bottom right,
      var(--orange),
      var(--pink),
      var(--lavender),
      var(--orange)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .recent-select-board-button:hover span {
    color: transparent;
    background-image: linear-gradient(
      to bottom right,
      var(--orange),
      var(--pink),
      var(--lavender),
      var(--orange)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .add-project-icon {
    stroke: var(--white) !important;
  }

  .recent-create-board-text {
    display: flex;
    align-items: center;
    gap: 45px;
  }

  .limit-reminder-text {
    color: var(--white);
  }

  .recent-create-board-title {
    color: var(--white) !important;
    min-width: 96px;
  }

  .project-card-pro {
    position: absolute;
    left: -73px;
    top: 40px;
  }

  .upload-card-pro {
    position: absolute;
    right: -30px;
    top: 5px;
  }
}

.recent-boards-conatiner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
