@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './styles/styles.css';
@import './styles/variables.css';
@import './styles/mainMenu.css';
@import './styles/account.css';
@import './styles/team.css';
@import './styles/elements.css';
@import './styles/registration.css';
@import './styles/modal.css';
@import './styles/loading.css';
@import './styles/outlineView.css';
@import './styles/verticalMenu.css';
@import './styles/nodeConnection.css';
@import './styles/svgColors.css';
@import './styles/calendar.css';
@import './styles/ModalTheme.css';
@import './styles/toolTip.css';

html {
  font-family: 'Inter';
  height: 100vh;
  color: var(--black);
}

body {
  margin: 0;
  font-family: 'Inter', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: var(--white);
}

* {
  box-sizing: border-box;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-element {
  width: 100% !important;
  height: 100% !important;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.svgColor {
  fill: currentColor;
}
.boardTimer {
  fill: var(--gray_01);
}

.cursor-pointer {
  cursor: var(--pointer);
}

.cursor-grab {
  cursor: var(--grab);
}

.cursor-text {
  cursor: text;
}

.hover:hover {
  background: var(--gray_06);
  box-sizing: border-box;

  border-radius: 3px;
}

.btnHover:hover {
  box-sizing: border-box;
  border: 2px solid var(--dark-30p);
}

.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
}
.flex-jc-sb {
  justify-content: space-between;
}

.mh-5 {
  margin: 0 5px;
}
.mx-5 {
  margin: 0 5px 0 5px;
}
.mx-10 {
  margin: 0 10px 0 10px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-5{
  margin-right: 5px;
}
.w-100 {
  width: 100%;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* [placeholder]:empty::before {
  content: attr(placeholder);
}

[placeholder]:empty:focus::before {
  content: "";
} */

/* [contentEditable] {
  content: attr(placeholder);
  padding: 12px;
  background: #eee;
}

[data-placeholder]:empty:before {
  content: attr(placeholder);
  color: #888;
  font-style: italic;
} */

.EmojiPickerReact {
  --epr-emoji-size: 25px;
  --epr-category-navigation-button-size: 20px;
  font-size: 15px;
}

.icon-plus-bg{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-plus{
  stroke: var(--gray_03) !important;
}

.icon-black {
  fill: var(--black);
}

.icon-stroke-black {
  stroke: var(--black);
}

.box-shadow {
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
}
