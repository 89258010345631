.font-14-med {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--black);
}

.rdp-root {
  background-color: var(--white);
  width: 296px;
  border-radius: 4px;
  padding: 10px;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);

  .rdp-nav {
    width: 100%;
    justify-content: space-between;
  }

  .rdp-month_caption {
    justify-content: center;
  }

  .rdp-button_previous {
    margin-left: 20px;
  }

  .rdp-button_next {
    margin-right: 20px;
  }

  .rdp-weekday {
    color: var(--gray_01);
    @extend .font-14-med;
  }

  .rdp-day {
    width: 38px;
    height: 36px;
    @extend .font-14-med;
    color: var(--black);
  }

  .rdp-selected {
    color: var(--white) !important;
    border: none;
    border-radius: 10px;
    background-color: var(--blue_strong) !important;
  }

  .rdp-day_button {
    border: none;
    width: 38px;
    height: 36px;
  }

  .rdp-today:not(.rdp-outside) {
    color: var(--black);
    background-color: var(--green_light);
    border: none;
    border-radius: 10px;
  }

  .rdp-range_start,
  .rdp-range_end {
    background: var(--blue_strong);
  }

  .rdp-range_start .rdp-day_button {
    background-color: var(--blue_strong);
  }

  .rdp-range_end .rdp-day_button {
    background-color: var(--blue_strong);
  }

  .rdp-range_middle .rdp-day_button {
    background: var(--blue_light);
    width: 109%;
    height: 106%;
    margin-left: -2px;
  }
}

.input-container {
  width: 100%;
  height: 36px;
  border: 1px solid var(--gray_05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;

  .input-item {
    width: 85%;
    height: 100%;
  }

  .input-item::placeholder {
    color: var(--gray_01);
  }

  .input-item:disabled {
    background-color: var(--white);
  }

  .input-icon {
    stroke: var(--white);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--gray_04);
    padding: 3px;
    display: flex;
    align-items: center;
  }
}

.date-picker-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;

  .date-picker-switch-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .clear-data {
    padding: 7px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .clear-data:hover {
    background-color: var(--gray_06);
  }

  .date-picker-switch {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    border-top: 1px solid var(--gray_05);
    border-bottom: 1px solid var(--gray_05);
  }

  .date-picker-delete-data {
    color: var(--gray_01);
  }
}

.date-picker-range-time {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.single-date-time {
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  width: 100%;
  align-items: center;
  color: var(--gray_03);

  .half-width {
    width: 50%;
  }
}

.date-picker-reminder-container {
  border: 1px solid var(--gray_06);
  padding: 8px;
  outline: none;
  border-radius: 4px;
}

.date-picker-reminder-container:hover {
  border: 1px solid var(--gray_04)
}
